import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Table, Breadcrumb, Badge, Alert, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as FDS from '@arctravel/react-fds/lib';
import { useSelector, useDispatch } from 'react-redux';
import { useExcelDownloder } from 'react-xls';
import { DynamicPagination } from '../utils/pagination/DynamicPagination';
import { Const } from '../utils';

declare const window: any;

export const CustomerInfo = (props: any) => {
    const dispatch = useDispatch();
    const { ExcelDownloder, Type } = useExcelDownloder();
    const [showIndex, setShowIndex] = useState([-1]);
    const [customerCount, setCustomerCount] = useState(0);
    const [customers, setCustomers]: any[] = useState([]);
    const customersRef = useSelector((state: any) => state.customersRef);

    const itemPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1)

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch({ type: "REPORT_IP", data: {} });
        dispatch({
            type: "GET_USERS",
            data: [],
        });
    }, [dispatch]);

    useEffect(() => {
        setCustomers(
            customersRef?.customer?.org === Const.ARC_ROLE ?
                customersRef.customers.filter((cust: any) => !((cust.org === Const.ARC_ROLE || cust.name === Const.ARC_ROLE) && Const.CUSTOMER_ENTITIES.indexOf(cust.entity) > 0)).sort((a: any, b: any) => a.name > b.name ? 1 : -1) :
                customersRef.customers.filter((cust: any) => cust.org === customersRef.customer.org)
        );
        setCustomerCount(customersRef.customers.length);
    }, [customersRef])

    const handleSearchChanged = (e: any) => {
        setCurrentPage(1);
        const filterTitle = customersRef?.customers &&
            [...customersRef.customers]
                .filter((msg: any) => ([msg.name, msg.entity].join(" "))?.toLowerCase()?.indexOf(e.target.value?.toLowerCase()) >= 0)
                .filter((msg: any) => (msg.org !== Const.ARC_ROLE) || (msg.org === Const.ARC_ROLE && [Const.AGENCY, Const.THIRDPARTY_FINANCIAL, Const.THIRDPARTY_NON_FINANCIAL].indexOf(msg.entity) < 0))
        setCustomers(filterTitle)
    }

    const getDownloadData = (() => {
        return {
            "Customers List": customersRef.customers
                .filter((cust: any) => !(cust.org === Const.ARC_ROLE && Const.CUSTOMER_ENTITIES.indexOf(cust.entity) > 0))
                .sort((a: any, b: any) => a.name > b.name ? 1 : -1)
                .map((item: any) => {
                    return {
                        "Org": item.org,
                        "Code": item.code,
                        "Customer Name": item.name,
                        "Contract Start Date": item.contract_start,
                        "Contract End Date": item.contract_end,
                        "Customer Type": item.entity,
                        "Maximum Allowed Users": item.max_allowed_users,
                        "Geography": Object.keys(item.geography)?.map((k: string) => (k.charAt(0).toUpperCase() + k.slice(1) + " (" + item.geography[k].join(", ") + ")")).join(", \u2029") || "-",
                        "Data Source": item.data_source?.join(", \u2029"),
                        "Data Content": item.data_content?.join(", \u2029")
                    }
                })
        }
    });

    return (
        <FDS.FDSLevel mainTitle={"Customer List"} subTitle={"View and manage customer information, user access criteria"} type="three" name="adminInfoPage">
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item href="#/admin">Admin</Breadcrumb.Item>
                    <Breadcrumb.Item active>Customer List</Breadcrumb.Item>
                </Breadcrumb>
            </Container>

            {customerCount > 0 ?
                <FDS.FDSLevel.Centered>
                    <Row className="fds-pl-column p-0 mb-0">
                        <Col className={`ADMIN pt-3 pb-3`} style={{ minHeight: '450px' }}>
                            <div>
                                <Row className='pb-3'>
                                    <Col>
                                        <Form.Control id="filterId" name="filterId" type="text" autoComplete="off" onChange={(ev) => handleSearchChanged(ev)} placeholder='Search Customers' style={{ width: "100%" }} />
                                        <Form.Text>Search for customer by name or type</Form.Text>
                                    </Col>
                                    <Col className='right'>
                                        {customers.length > 0 ?
                                            <ExcelDownloder
                                                className="btn btn-primary mt-4"
                                                data={getDownloadData()}
                                                filename={"CustomersList"}
                                                type={Type.Button}
                                            >
                                                Export Data
                                            </ExcelDownloder> : null
                                        }
                                    </Col>
                                </Row>
                                {customers.length > 0 ?
                                    <div style={{ width: "100%", overflow: "auto" }}>
                                        <Table className='fds-table table-striped'>
                                            <thead>
                                                <tr>
                                                    <th>Customer Name</th>
                                                    <th>Code</th>
                                                    <th>Contract Start Date</th>
                                                    <th>Contract End Date</th>
                                                    <th>Customer Type</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(customers.slice((currentPage - 1) * itemPerPage, (currentPage - 1) * itemPerPage + itemPerPage) || []).map((customer: any, i: number) => {
                                                    return (<tr key={i}>
                                                        <td>
                                                            {Object.keys(customer.geography).length + customer?.modules?.length > 0 ?
                                                                <i className={`${showIndex.indexOf(i) >= 0 ? "fds-glyphs-arrow-down5" : "fds-glyphs-caret-right"} me-2`}
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        setShowIndex((prev: number[]) => {
                                                                            const ind = prev.indexOf(i);
                                                                            if (ind >= 0) {
                                                                                return [...prev.filter(item => item !== i)];
                                                                            } else {
                                                                                return [...prev, i]
                                                                            }
                                                                        });
                                                                    }}
                                                                /> : null
                                                            }

                                                            <Link to={customer.org === "ARC" ? "/admin/customerinfo" : "/admin/customer-details/" + customer.org} className="nav-link" style={{ display: "inline-block", marginLeft: Object.keys(customer.geography)?.length + customer?.modules?.length > 0 ? "0" : "24px" }}>
                                                                <b>{customer.name}</b>
                                                            </Link>
                                                            {showIndex.indexOf(i) >= 0 ? <div className='pt-2 ms-3 ps-3'>
                                                                {Object.keys(customer.geography).length > 0 ?
                                                                    Object.keys(customer.geography).map((k: string, ki: number) => {
                                                                        return (
                                                                            <div className='pb-1' key={ki}>
                                                                                <div style={{ textTransform: "capitalize", fontSize: "0.75rem", fontWeight: "bold" }}>
                                                                                    <label><b>{k}</b></label>
                                                                                </div>
                                                                                <div>
                                                                                    {customer.geography[k].map((geog: string, gi: number) => {
                                                                                        return <Badge key={gi} className="p-1" bg="primary">{geog}</Badge>
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }) :
                                                                    null
                                                                }
                                                            </div> : null}
                                                            {showIndex.indexOf(i) >= 0 ? <div className='pt-2 ms-3 ps-3'>
                                                                <div style={{ textTransform: "capitalize", fontSize: "0.75rem", fontWeight: "bold" }}>
                                                                    <label><b>Modules</b></label>
                                                                </div>
                                                                {customer?.modules?.length > 0 ?
                                                                    customer?.modules?.map((k: string, ki: number) => {
                                                                        return (<Badge key={ki} className="p-1" bg="info">{k}</Badge>)
                                                                    }) :
                                                                    null
                                                                }
                                                            </div> : null}
                                                        </td>
                                                        <td>{customer.org === customer.code ? customer.code : customer.org + (customer.code.length > 1 ? (" - " + customer.code) : "")}</td>
                                                        <td>{customer.contract_start}</td>
                                                        <td>{customer.contract_end}</td>
                                                        <td>{customer.entity}</td>
                                                    </tr>)
                                                })}
                                            </tbody>
                                        </Table>
                                        {customers.length > itemPerPage ?
                                            <div style={{ textAlign: "center" }}>
                                                <DynamicPagination currentPage={currentPage} itemPerPage={itemPerPage} totalItems={customers.length} onPageChange={(page: number) => setCurrentPage(page)} />
                                            </div> : null
                                        }
                                    </div> :
                                    <Alert data-testid="table-main-no-data" className="mt-3" variant="primary">
                                        <div className="icon"><i className="fds-glyphs-info2"></i></div>
                                        <div className="body">There are no records for this search criteria.</div>
                                    </Alert>
                                }
                            </div>
                        </Col>
                    </Row>
                </FDS.FDSLevel.Centered> :
                <Container className='mt-3 pt-3'>
                    <Alert data-testid="table-main-no-data" variant="primary">
                        <div className="icon"><i className="fds-glyphs-info2"></i></div>
                        <div className="body">There are currently no customers available.</div>
                    </Alert>
                </Container>
            }
            <div className='p-3'></div>
        </FDS.FDSLevel>
    );
}