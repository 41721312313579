import axios from 'axios';
import { LocalStorageCache } from "@auth0/auth0-react";
import jwtDecode from "jwt-decode";
import { getOts } from '../../../okta/ls';
import { clearLocalStorage } from '../../../components/utils';

declare const window: any;

// authO Change
export const setLoginAction = (claims: any) => {
    return (dispatch: any) => {
        const idToken = claims?.__raw;
        const ots = getOts();
        ots.idToken = {
            "idToken": idToken,
            "name": claims?.name,
            "preferred_username": claims?.nickname,
            "sub": claims?.sub,
            "role": claims?.role.map((r: string) => r.toUpperCase()),
            "email": claims?.email,
            "orgName": claims?.org.toUpperCase(),
            "claims": claims
        };

        //Refresh Token:            
        const refresh_token = new LocalStorageCache();
        const key: any = refresh_token.allKeys().find(key => key.includes('auth0spajs') && key.includes(window.ENV.oktaConfig.domain));        
        const refresh_token_value: any = refresh_token.get(key);
        const finalRefreshToken = refresh_token_value?.body?.refresh_token
        localStorage.setItem("okta-token-storage", JSON.stringify(ots));
        localStorage.setItem("bearer", idToken ? idToken : '');
        localStorage.setItem("expiresAt", JSON.stringify(claims.exp));
        localStorage.setItem("isTimeoutShowed", "false");
        localStorage.setItem("isRefreshCalled", "false");
        localStorage.setItem('refresh_token', claims.refresh_token ? claims.refresh_token : finalRefreshToken);

        return { ...ots, bearer: idToken }
    }
};

export const getLoginAction = () => {
    
    return (dispatch: any) => {
        const config = window.ENV.oktaConfig;
        const idToken = getOts()?.idToken?.idToken
        const refreshToken = localStorage.getItem("refresh_token")
        const options: any = {
            method: 'POST',
            url: `https://${config.domain}/oauth/token`,
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                authorization: `Bearer ${idToken}`
            },
            data: new URLSearchParams({
                grant_type: 'refresh_token',
                client_id: config.clientId,
                refresh_token: `${refreshToken}`,
                scope: "offline_access openid profile email"
            })
        };

        axios.request(options).then(function (response) {
            const iToken = response.data.id_token;
            const rToken = response.data.refresh_token
            const claims: any = jwtDecode(iToken)
            claims.refresh_token = rToken
            claims.__raw = iToken
            dispatch(setLoginAction(claims))
        }).catch(function (error) {
            localStorage.setItem("isRefreshCalled", "false");
            dispatch({ type: "LOADING", data: "" });
            dispatch({ type: "STATUS", data: { type: "DANGER", title: "Error", message: `The server encountered an error and could not complete your request. (${error.message})` } });
            clearLocalStorage()
        });


    }
};
