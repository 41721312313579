import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Col, Row, Card } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { getOts, getOtsLength } from '../../okta/ls';
import { capitalize, Const } from '../utils';
// import { useAuth0 } from '@auth0/auth0-react';

import * as FDS from '@arctravel/react-fds/lib';
import Feedback from '../modals/Feedback';
import './home.scss'

import ReleaseNote from '../ReleaseNote/ReleaseNote';
import Dismissable from '../utils/dismissable/Dismissable';

declare const window: any;

const hour = new Date().getHours();

interface ReportTitlesProps {
    id: string;
    color?: string;
    mainTitle: string;
    showFor: string;
    subTitle: string;
    parent?: string;
    card: { text: string; goURL: { title: string, url: string }[] };
    shortTitle?: string,
    childTitle?: string,
}
type titleProps = { [key: string]: ReportTitlesProps };

const Home = () => {
    const insightDescription = "Your central data platform enabling access to a comprehensive set of ticketed and financially settled airline data using integrated datasets and modernized industry-accepted application of data metrics and logic. Easy-to-use, fast and focused use cases will point you in the right direction for all your insight needs."
    const arcciDescription = "Enables more effective relationships between airlines and their corporate customers by aggregating data, delivering corporate insights and streamlining key business processes. ARC aggregates data from a corporate travel program’s TMC and airline direct bookings, gathers the transactions, validates and displays them for all stakeholders."
    const historyNav = useHistory();
    const dispatch = useDispatch();
    const [user, setUser] = useState(getOtsLength() > 0 ? capitalize(getOts()?.idToken?.claims?.name) : "");
    const customersRef: any = useSelector((state: any) => state.customersRef);
    const [titles, setTitles]: any = useState({})

    const [mainTitle, setMainTitle] = useState('');
    const [flashMsg, setFlashMsg] = useState([]);
    const flashMessagesRef: any = useSelector((state: any) => state.messagesRef['flash_Message']);
    const lastModifiedDataTimeStamp = useSelector((state: any) => state.referenceDataRef[window.ENV.references[customersRef?.customer?.modules?.toString()?.indexOf("INSIGHTS") >= 0 ? "lastmodifiedTrip" : "lastmodifiedDrf"]]);

    useEffect(() => {
        dispatch({ type: "REPORT_IP", data: {} });
        // sorting and filter for flash messages
        if (flashMessagesRef?.data?.length > 0) {
            let filteredMsg;
            if (!localStorage.getItem("REF:flashChecked")) {
                filteredMsg = flashMessagesRef.data.filter((msg: any) => new Date(msg.active.endDate[0]) >= new Date(new Date().setHours(0, 0, 0, 0)))
            } else {
                const msgStatus = JSON.parse(localStorage.getItem("REF:flashChecked")!)
                filteredMsg = flashMessagesRef.data.filter((msg: any) => msgStatus.indexOf(msg.title) < 0 && new Date(msg.active.endDate[0]) >= new Date(new Date().setHours(0, 0, 0, 0)))
            }
            setFlashMsg(filteredMsg)
        }
    }, [dispatch, flashMessagesRef])

    useEffect(() => {
        setMainTitle("Good " + (hour < 12 ? "Morning" : hour < 18 ? "Afternoon" : "Evening") + ", " + user);
    }, [dispatch, user]);

    useEffect(() => {
        if (customersRef.customer?.data_source && customersRef.customer?.modules) {
            // modifying Title Object and goURL for home page
            const envReportTitles: titleProps = JSON.parse(JSON.stringify(window.ENV.props.reportTitles || {}));

            const reportTitles: titleProps = Object.entries(envReportTitles).reduce((titles: titleProps, [key, value]: [string, ReportTitlesProps]) => {
                if (value.parent) {
                    const parentObj = titles[value.parent];
                    if (parentObj) {
                        value.color = parentObj.color;
                    }
                }
                titles[key] = value;
                return titles;
            }, {} as titleProps);

            const filteredTitles: titleProps = Object.entries(reportTitles).reduce((title: titleProps, [key, value]: [string, ReportTitlesProps]) => {
                if (customersRef.customer?.modules?.includes(value?.showFor)) {
                    if (value.parent) {
                        const parentObj = title[value.parent];
                        if (parentObj) {
                            value.card.goURL.forEach((childGoURL) => {
                                const isDuplicate = parentObj.card.goURL.some((parentGoURL) => parentGoURL.url === childGoURL.url);
                                // Only concatenate if not a duplicate
                                !isDuplicate && parentObj.card.goURL.push(childGoURL);
                            });
                        } else {
                            title[key] = value;
                        }
                    } else {
                        title[key] = value;
                    }
                }
                return title;
            }, {} as titleProps);

            setTitles({
                ...filteredTitles,
                ...Object.fromEntries(Object.entries(window.ENV.props.otherTitles).filter(([key]: any) => customersRef.customer?.modules?.indexOf(window.ENV.props.otherTitles[key].showFor) >= 0))
            });
            setUser(getOtsLength() > 0 ? capitalize(getOts()?.idToken?.claims?.name) : "");
        }

    }, [customersRef.customer]);

    useEffect(() => { }, [historyNav.location.pathname]);

    return (
        <React.Fragment>
            {flashMsg.map((obj: any) => { return <Dismissable key={obj.title} obj={obj} /> })}
            <Feedback page="Home" />
            <FDS.FDSLevel type="two" name="homePage" mainTitle={mainTitle} subTitle={`Welcome to ${historyNav.location?.pathname?.indexOf(Const.ARCCI) >= 1000 || (customersRef.customer?.modules?.indexOf(Const.ARCCI) >= 1000 && customersRef.customer?.org !== Const.ARC_ROLE) ? "ARC " + Const.ARCCI_ProductName : Const.ProductName}! Explore, Ask, Answer.`}>
                {lastModifiedDataTimeStamp && <Row className="fds-pl-container dataRefresh">
                    <Col>
                        Last Data Refresh {new Date(lastModifiedDataTimeStamp[0]?.date).toLocaleDateString()} {new Date(lastModifiedDataTimeStamp[0]?.date).toLocaleTimeString()}
                    </Col>
                </Row>}
                <FDS.FDSLevel.Centered>
                    <Col>
                        <Row className='cardWraps pt-3'>
                            <Col>
                                {Object.keys(titles).length && customersRef.customer?.modules?.length ? <>
                                    <Row className="ms-2 me-2">
                                        <Col className='text-center'>
                                            {customersRef.customer?.modules?.toString().indexOf("INSIGHTS") >= 0 ? <><b>Discovery Point</b> - {insightDescription}</> : <><b>ARC Corporate Intelligence</b> - {arcciDescription}</>}
                                        </Col>
                                    </Row>
                                    {/*<div>
                                        {Object.keys(window.ENV.props.reportTitles)
                                            .filter((titleId: string) => {
                                                return JSON.parse(localStorage.getItem("frequent") || "{}")[titleId] > 0
                                            })
                                            .map((titleId: string) => {
                                                return { titleId: titleId, value: JSON.parse(localStorage.getItem("frequent") || "{}")[titleId] }
                                            })
                                            .sort((objA: any, objB: any) => {
                                                return objA.value >= objB.value ? -1 : 1;
                                            })
                                            .map((obj: any, i: number) => {
                                                const frequent = JSON.parse(localStorage.getItem("frequent") || "{}");
                                                const titleObj = window.ENV.props.reportTitles[obj.titleId];
                                                return <Card
                                                    key={i}
                                                    className={`p-2 mt-3 primary with-routing`}
                                                >
                                                    <Card.Body>
                                                        <Card.Title as="h2">
                                                            <div className='textAsTitleIcon' style={{ background: titleObj.color }}>
                                                                <span>
                                                                    {titleObj.mainTitle.match(/\b(\w)/g).join('')} {titleObj.childTitle}
                                                                </span>
                                                            </div>
                                                            <span className='reportName'>{titleObj.mainTitle}</span>
                                                        </Card.Title>
                                                    </Card.Body>
                                                </Card>
                                            })}
                                    </div>*/}
                                    <Row className="mt-2" style={{ justifyContent: Object.keys(titles).length > 2 ? "left" : "center" }}>
                                        {Object.keys(titles).map((titleId: string, i: number) => {
                                            const titleObj = titles[titleId];
                                            return (<Col sm={4} key={i}>
                                                <Card
                                                    className={`appsWrap primary with-routing`}
                                                >
                                                    <button className={titleObj.card?.goURL.length > 1 ? "border-0" : ""} onClick={() => {
                                                        if (titleObj.card?.goURL.length === 1) {
                                                            historyNav.push(titleObj.card?.goURL[0].url);
                                                        }
                                                    }}>
                                                        <Card.Body>
                                                            <Card.Title as="h2">
                                                                <div className='textAsTitleIcon' style={{ background: titleObj.color }}>
                                                                    <span>
                                                                        {titleObj.mainTitle.match(/\b(\w)/g).join('')}
                                                                    </span>
                                                                </div>
                                                                <span className='reportName'>{titleObj.mainTitle}</span>
                                                            </Card.Title>
                                                            <Card.Text>
                                                                <div className='pt-3'>{titleObj.card?.text}</div>
                                                            </Card.Text>
                                                        </Card.Body>
                                                        <Card.Footer>
                                                            {
                                                                titleObj.card?.goURL.length > 1 ?
                                                                    <div className="d-flex justify-content-between">
                                                                        {titleObj.card?.goURL.map((item: any, j: number) => <Link key={j} to={item.url}>{item.title}</Link>)}
                                                                    </div> :
                                                                    <div className="d-flex justify-content-end align-items-center">
                                                                        <i className="arrow fds-glyphs-arrow-right3"></i>
                                                                    </div>
                                                            }
                                                        </Card.Footer>
                                                    </button>
                                                </Card>
                                            </Col>)
                                        })}
                                    </Row></> :
                                    <>
                                        <Row className="ms-2 me-2">
                                            <Col className='text-center'>
                                                <div className="loadMore"><div className="bar mb-4" /><div className="bar mb-4" /></div>
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            {[1, 2, 3].map((i: number) => {
                                                return (<Col sm={4} key={i}>
                                                    <Card>
                                                        <Card.Body>
                                                            <Card.Title as="h2"><div className="loadMore"><div className="bar" /></div></Card.Title>
                                                            <Card.Text>
                                                                <div className="loadMore"><div className="bar mb-3" /><div className="bar" /></div>
                                                            </Card.Text>
                                                        </Card.Body>
                                                        <Card.Footer><div className="loadMore"><div className="bar" /></div></Card.Footer>
                                                    </Card>
                                                </Col>)
                                            })}
                                        </Row>
                                    </>
                                }
                            </Col>
                        </Row>

                        <div className='pt-3 pb-3 mt-3 mb-3'></div>
                    </Col>
                </FDS.FDSLevel.Centered>
                <ReleaseNote />
                <div></div>
            </FDS.FDSLevel>
        </React.Fragment>
    )
}

export default Home;