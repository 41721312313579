import axios from 'axios';
import { processResponse } from '../../../components/utils';

declare const window: any;

export const getAppMessageAction = () => {
    return async (dispatch: any) => {
        //dispatch({ type: "LOADING", data: "Loading Messages" });
        const read = async (url: string, field: string) => {
            return await axios.get(url + `&timestamp=${new Date().getTime()}`, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem("bearer"),
                    'Content-Type': 'application/json'
                }
            }).then((response: any) => {
                const data = processResponse(response);
                return { "title": field, "data": data, "lastModified": response.headers['last-modified'] }
            }).catch((err: any) => {
                dispatch({ type: "STATUS", data: { type: "DANGER", title: "Error", message: `The ${field} server encountered an error and could not complete your request. (${err.message})` } });
                dispatch({ type: "LOADING", data: "" });
            })
        }

        await Promise.all(Object.keys(window.ENV.messages).map(async (field: any, i: number) => {
            return read(window.ENV.messages[field], field);
        })).then((response: any) => {
            let datas: any = {}
            response.forEach((res: any) => {
                const { title, data, lastModified } = res
                datas[title] = { "data": data, "lastModified": lastModified }
            })
            dispatch({
                type: 'APP_MESSAGES',
                data: datas
            });
        }).catch((err: any) => {
            dispatch({ type: "STATUS", data: { type: "DANGER", title: "Error", message: `${err.message}` } });
            dispatch({ type: "LOADING", data: "" });
        })
    }
};

export const saveAppMessageAction = (body: any, type: string) => {
    return (dispatch: any) => {
        axios.post(window.ENV.messages[type], [...body], {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("bearer"),
                'Content-Type': 'application/json'
            }
        }).then((response: any) => {
            dispatch(getAppMessageAction());
            dispatch({ type: "LOADING", data: "" });
            dispatch({ type: "STATUS", data: { type: "SUCCESS", title: "Success", message: `Message management succesfully updated` } });
        }).catch((err: any) => {
            dispatch({ type: "LOADING", data: "" });
            dispatch({ type: "STATUS", data: { type: "DANGER", title: "Error", message: `The server encountered an error and could not complete your request. (${err.message})` } });
        });
    }
}