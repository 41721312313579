import React, { useState, useEffect } from 'react';
import { Row, Col, Dropdown, ButtonGroup, Button } from 'react-bootstrap';
import * as FDS from '@arctravel/react-fds/lib';
import { useDispatch, useSelector } from 'react-redux';
import { downloadReport } from '../../store/actions';
import { templates, clearTheme, setTheme } from '../utils';

export const TitlePanel = (props: any) => {
    const dispatch = useDispatch();
    const notificationRef = useSelector((store: any) => store.notificationRef);
    const [nData, setNData] = useState(notificationRef);

    const downloadAs = (type: "PRINT" | "PDF" | "PNG") => {
        clearTheme("night");
        clearTheme("night-bg-image");
        FDS.html2Image(document?.querySelector("#resultsWrap"), type, props.parentProps.params, true);
        if (props.parentProps.params.indexOf("ARCCIDS") >= 0) {
            setTimeout(() => {
                setTheme("night");
                setTheme("night-bg-image");
            }, 50);
        }
    }

    useEffect(() => {
        if (notificationRef.body?.notificationType === "reportExport") {
            setNData(notificationRef);
        }
    }, [notificationRef])

    return (
        <>
            {props.showReport && nData?.body?.notificationType === "reportExport" && nData?.body?.reportName.indexOf(props.repName) >= 0 ?
                <Col>
                    <Row className="menuWrap">
                        <Col>
                            <div className='printWrap right no-print'>
                                {(props.parentProps.onLoadRun || (props.tab === "tab2" && templates[props.parentProps.params].chartsLists?.length > 0)) && props.showReport ?
                                    <Dropdown as={ButtonGroup}>
                                        <Button variant="primary">Export All</Button>
                                        <Dropdown.Toggle split variant="primary"></Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#" onClick={() => {
                                                downloadAs("PRINT");
                                            }}><i className='fds-glyphs-printer3' /> Print</Dropdown.Item>
                                            <Dropdown.Item href="#" onClick={() => {
                                                downloadAs("PNG");
                                            }}><i className="fds-glyphs-file-stats" /> Image <small>(.png)</small></Dropdown.Item>
                                            <Dropdown.Item href="#" onClick={() => {
                                                downloadAs("PDF");
                                            }}><i className="fds-glyphs-file-pdf" /> PDF <small>(.pdf)</small></Dropdown.Item>
                                            <Dropdown.Item href="#" onClick={() => dispatch(downloadReport(props.repName))}><i className="fds-glyphs-file-spreadsheet" /> Excel Data <small>(.xlsx)</small></Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown> :
                                    <Dropdown>
                                        <a href="/" style={{ fontWeight: "600" }} onClick={(ev: any) => { ev.preventDefault(); dispatch(downloadReport(props.repName)) }}><i className="fds-glyphs-download" /> Download Report</a>
                                    </Dropdown>
                                }
                            </div>
                        </Col>
                        <Col className='resizeIcon' onClick={(ev: any) => {
                            props.expandResults(ev);
                        }}>
                            <i data-testid="Expand" className='fds-glyphs-arrow-resize1' title="Expand" />
                            <i data-testid="Close" className='fds-glyphs-clear' title="Close" />
                        </Col>
                    </Row>
                    <Row>

                    </Row>
                </Col>
                :
                null
            }
        </>
    )
}