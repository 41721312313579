import axios from "axios";
import { processResponse } from '../../../components/utils';

declare const window: any;

export const getHistoryAction = (type: string) => {
  return (dispatch: any) => {
    dispatch({ type: "LOADING", data: "Loading History Data" });
    axios
      .get(window.ENV.historyDomain + "?requestMode=" + type, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("bearer"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const data = processResponse(response);
        dispatch({
          type: "SAVED_HISTORY",
          data: data.result.reverse(),
        });
        dispatch({ type: "LOADING", data: "" });
      })
      .catch((err) => {
        dispatch({ type: "LOADING", data: "" });
        dispatch({
          type: "SAVED_HISTORY",
          data: [],
        });
        dispatch({
          type: "STATUS",
          data: {
            type: "DANGER",
            title: "Error",
            message: `The server encountered an error and could not complete your request. (${err.message})`,
          },
        });
      });
  };
};

export const removeHistoryAction = (reportName: string) => {
  return (dispatch: any) => {
    dispatch({ type: "LOADING", data: `Deleting ${reportName}` });
    axios
      .delete(window.ENV.historyDomain + "?report=" + reportName, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("bearer"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        dispatch(getHistoryAction(reportName?.toUpperCase()?.indexOf("EXTERNAL_EVENT") > 0 ? "EXTERNAL_EVENT" : reportName.indexOf("_SCHED") >= 0 ? "SCHED" : "ADHOC"));
        dispatch({
          type: "STATUS",
          data: {
            type: "SUCCESS",
            title: "Deleted Successfully",
            message: `${reportName}`,
          },
        });
        dispatch({ type: "LOADING", data: "" });
      })
      .catch((err) => {
        dispatch({ type: "LOADING", data: "" });
        dispatch({
          type: "STATUS",
          data: {
            type: "DANGER",
            title: "Error",
            message: `The server encountered an error and could not complete your request. (${err.message})`,
          },
        });
      });
  };
};
