import React, { useState, useEffect } from 'react';
//import axios, { AxiosError } from 'axios';
import { Row, Col, Tabs, Tab, Table, Modal, Card, CardBody, CardText, CardTitle, OverlayTrigger, Tooltip, Badge } from 'react-bootstrap';
//import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import * as FDS from '@arctravel/react-fds';
import { DynamicPagination } from '../../utils/pagination/DynamicPagination';
//import { processResponse } from "../../utils";
import SearchSortHeader from "../../utils/SearchSortHeader";

import './index.scss';

//declare const window: any;

const dataFlowColorMap: any = {
    "G": "successTxt",
    "Y": "warningTxt",
    "R": "dangerTxt",
    "NA": "dangerTxt"
}

interface ARCCIDataStatusProps {
    data: any
}

const asTooltip = (value: any) => {
    return ({
        "Approved": "Corporation approved DRA",
        "Terminated": "DRA expired or no longer active",
        "Pending Approval": "DRA with Corporation for approval",
        "Unknown": "Unknown"
    } as any)[value || "Approved"];
}

const draDataFlowRenderer = (value: any) => {
    return (
        <b className={dataFlowColorMap[value?.toUpperCase()] || "dangerTxt"}>
            {value !== "" && value?.toUpperCase() !== "NA" ? '✔' : '✖'}
        </b>
    )
}

const DPTable = (props: any) => {
    //const dispatch = useDispatch();

    const [currentPage, setCurrentPage] = useState(1);
    const [results, setResults]: any = useState([]);
    const [filterIp, setFilterIp]: any = useState({});
    const [headers, setHeaders]: any = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [detailedObject, setDetailedObject]: any = useState({});

    const [sortKey, setSortKey] = useState("");
    const [sortOrder, setSortOrder] = useState("ascn");

    const getTCV = (row: any) => {
        /*
        dispatch({ type: "LOADING", data: "Downloading data" });
        axios.post(window.ENV.reportsDomain, {
            "reportType": "ARCCIDS",
            "tc_validation_chart": true,
            "corp_id": row.ADC_CARR_CORP_ID,
            "agent_id": row.ADC_AGENCY_NAME
        }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("bearer"),
                'Content-Type': 'application/json'
            }
        }).then((response: any) => {
            const data = processResponse(response);
            //setModalData({ data: data.sort((a: any, b: any) => new Date(a["ISSUE_MONTH"]) > new Date(b["ISSUE_MONTH"]) ? -1 : 1) });
            dispatch({ type: "LOADING", data: "" });
            if (data.length === 0) {
                dispatch({ type: "STATUS", data: { type: "INFO", title: "No Data Available", message: "Tour Code Validation data is not available for the selected Corporation and Agent." } });
            }
        }).catch((error: AxiosError) => {
            //setModalData({ data: [] });
            dispatch({ type: "LOADING", data: "" });
            dispatch({ type: "STATUS", data: { type: "DANGER", title: "Request Failed", message: "Tour Code Validation Request Failed. Contact system administrator." } });
        });
        */
    }

    const getTCVColor = (value: number) => {
        return value >= 0.75 ?
            'success' :
            (value >= 0.50 && value < 0.75) ?
                'warning' :
                (value > 0 && value < 0.49) ?
                    'danger' :
                    'secondary';
    }

    const getYNColor = (value: string) => {
        return value?.toLowerCase().indexOf('yes') >= 0 ?
            'success' :
            value?.toLowerCase().indexOf('no') >= 0 ?
                'danger' :
                'secondary';
    }

    const getMSColor = (value: string) => {
        return value?.toLowerCase() === "complete"
            ? 'success d100' :
            value?.toLowerCase() === "incomplete" ?
                'danger d50' :
                'secondary d100';
    }

    const statusTable = (r: any, index?: number) => {
        return (
            <>
                <div className='dataStatus p-3'>
                    <Row className='header'>
                        <Col></Col>
                        <Col>U.S. Point of Sale</Col>
                        <Col>Non-U.S. Point of Sale</Col>
                    </Row>
                    <Row>
                        <Col>Receiving Data</Col>
                        <Col>
                            <span className={`dot ${getYNColor(r.ASP_ANY_DATA)}`}></span>
                            {FDS.valueOrDash(r.ASP_ANY_DATA)}
                        </Col>
                        <Col>
                            <span className={`dot ${getYNColor(r.BSP_ANY_DATA)}`}></span>
                            {FDS.valueOrDash(r.BSP_ANY_DATA)}
                        </Col>
                    </Row>
                    <Row>
                        <Col>Current Data</Col>
                        <Col>
                            <span className={`dot ${getYNColor(r.ASP_UPDATED_DATA)}`}></span>
                            {FDS.valueOrDash(["no", "not receiving"].indexOf(r.ASP_ANY_DATA?.toLowerCase()) >= 0 ? "N/A" : r.ASP_UPDATED_DATA)}
                        </Col>
                        <Col>
                            <span className={`dot ${getYNColor(r.BSP_UPDATED_DATA)}`}></span>
                            {FDS.valueOrDash(["no", "not receiving"].indexOf(r.BSP_ANY_DATA?.toLowerCase()) >= 0 ? "N/A" : r.BSP_UPDATED_DATA)}
                        </Col>
                    </Row>
                    <Row>
                        <Col>Historical Data</Col>
                        <Col>
                            <span className={`dot ${getYNColor(r.ASP_HISTORICAL_DATA)}`}></span>
                            <span>{FDS.valueOrDash(["no", "not receiving"].indexOf(r.ASP_ANY_DATA?.toLowerCase()) >= 0 ? "N/A" : r.ASP_HISTORICAL_DATA)}</span>
                        </Col>
                        <Col>
                            <span className={`dot ${getYNColor(r.BSP_HISTORICAL_DATA)}`}></span>
                            <span>{FDS.valueOrDash(["no", "not receiving"].indexOf(r.BSP_ANY_DATA?.toLowerCase()) >= 0 ? "N/A" : r.BSP_HISTORICAL_DATA)}</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col>Other Airline Data</Col>
                        <Col>
                            <span className={`dot ${getMSColor(["no", "not receiving"].indexOf(r.ASP_ANY_DATA?.toLowerCase()) >= 0 ? "N/A" : r.ASP_MARKET_SHARE)}`}></span>
                            <span className='text-capitalize'>{FDS.valueOrDash(["no", "not receiving"].indexOf(r.ASP_ANY_DATA?.toLowerCase()) >= 0 ? "N/A" : r.ASP_MARKET_SHARE)}</span>
                        </Col>
                        <Col>
                            <span className={`dot ${getMSColor(["no", "not receiving"].indexOf(r.BSP_ANY_DATA?.toLowerCase()) >= 0 ? "N/A" : r.BSP_MARKET_SHARE)}`}></span>
                            <span className='text-capitalize'>{FDS.valueOrDash(["no", "not receiving"].indexOf(r.BSP_ANY_DATA?.toLowerCase()) >= 0 ? "N/A" : r.BSP_MARKET_SHARE)}</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col onClick={() => { getTCV(r) }}>Tour Code Validation</Col>
                        <Col>
                            <span className={`dot d100 ${getTCVColor(r.ASP_TOTAL_RATE_LTM || 0)}`}></span>
                            {FDS.valueOrDash(r.ASP_TOTAL_RATE_LTM ? FDS.numberFormatTxt(r.ASP_TOTAL_RATE_LTM * 100, 2, '%') : r.ASP_TOTAL_RATE_LTM)}
                        </Col>
                        <Col>
                            <span className={`dot d100 ${getTCVColor(r.BSP_TOTAL_RATE_LTM || 0)}`}></span>
                            {FDS.valueOrDash(r.BSP_TOTAL_RATE_LTM ? FDS.numberFormatTxt(r.BSP_TOTAL_RATE_LTM * 100, 2, '%') : r.BSP_TOTAL_RATE_LTM)}
                        </Col>
                    </Row>
                </div>
            </>
        )
    }

    useEffect(() => {
        console.log("KKK:1:", sortKey, sortOrder)
        const sortData = (data: any[], filterIp?: any) => {
            if (!sortKey && !sortOrder) return data;

            const type = props.headers.filter((header: any) => header.key === sortKey)?.[0]?.filter?.type || "";

            const dateKeys = [...props.headers].reduce((dResults: any, header: any) => {
                if (header.filter?.type === "date") {
                    dResults.push(header.key?.toLowerCase());
                }
                return dResults;
            }, []);

            const sortedData = data.filter((obj: any) => {
                let count = 0;
                for (let hi = 0; hi < Object.keys(filterIp).length; hi++) {
                    const key = Object.keys(filterIp)[hi] || "";
                    const isNumber = ["=", ">", "<"].indexOf(((filterIp[key] || filterIp[key.toUpperCase()])?.toString() || "")?.charAt(0)) >= 0;
                    let isNumberValid = false;
                    if (isNumber) {
                        const symbolPart = (filterIp[key] || filterIp[key.toUpperCase()])?.charAt(0) || "=";
                        const numberPart = parseFloat((filterIp[key] || filterIp[key.toUpperCase()])?.replace(/[=<>]/gi, "")) || 0;
                        const dataNumber = parseFloat(obj[key] || obj[key.toUpperCase()]) || 0;

                        if (symbolPart === "=") {
                            isNumberValid = dataNumber === numberPart;
                        } else if (symbolPart === "<") {
                            isNumberValid = dataNumber < numberPart;
                        } else if (symbolPart === ">") {
                            isNumberValid = dataNumber > numberPart;
                        }
                    }
                    if (
                        (dateKeys?.indexOf(key?.toLowerCase()) >= 0 &&
                            new Date(new Date(obj[key]).setHours(0, 0, 0)) >= new Date((new Date(filterIp[key].startDate)).setHours(0, 0, 0)) &&
                            new Date(new Date(obj[key]).setHours(0, 0, 0)) <= new Date((new Date(filterIp[key].endDate)).setHours(0, 0, 0))
                        ) ||
                        (
                            (filterIp[key]?.length > 0 || filterIp[key.toUpperCase()]?.length > 0) &&
                            (
                                (isNumber && isNumberValid) ||
                                (obj[key] || obj[key.toUpperCase()] || "")?.toString()?.toLowerCase()?.indexOf((filterIp[key] || filterIp[key.toUpperCase()])?.toString()?.toLowerCase()) >= 0
                            )
                        )
                    ) {
                        count++;
                    }
                }
                return count === Object.keys(filterIp).filter(k => filterIp[k]?.length > 0 || filterIp[k].startDate?.length > 0).length;
            }).sort((a: any, b: any) => {
                return (
                    type === "number" ?
                        parseFloat(a[sortKey] || 0) >= parseFloat(b[sortKey] || 0) :
                        a[sortKey]?.toString()?.toLowerCase() >= b[sortKey]?.toString()?.toLowerCase()
                ) ? 1 : -1;
            });

            if (sortOrder === "desc") {
                return sortedData.reverse();
            }

            return sortedData;
        };

        setResults(sortData([...props.data], filterIp));
    }, [props.data, filterIp, props.headers, sortKey, sortOrder]);

    useEffect(() => {
        setHeaders(props.headers);
        setFilterIp({});
        setCurrentPage(1);
        setItemsPerPage(props.itemsPerPage);
        setSortKey(props.sortKey || props.headers[0].key || "");
        setSortOrder("ascn");
    }, [props.headers, props.sortKey, props.itemsPerPage]);

    return (
        <>
            {Object.keys(filterIp).length > 0 &&
                <div className='p-2'>
                    {Object.keys(filterIp).map((key: string, ki: number) => {
                        return <Badge className='fds-tag' key={ki} style={{ textTransform: "capitalize" }}>
                            <b>{[...headers]?.filter((head: any) => head.key === key)?.[0]?.label?.toLowerCase()}</b>:&nbsp;
                            {filterIp[key]?.startDate || filterIp[key]?.endDate ?
                                (filterIp[key]?.startDate + " - " + filterIp[key]?.endDate) :
                                [...headers]
                                    .filter((head: any) => head.filter?.type === "select" && head.key === key)?.[0]?.filter?.options
                                    .filter((op: any) => op.key?.toLowerCase() === filterIp[key]?.toLowerCase())?.[0]?.value ||
                                filterIp[key]
                            }
                            <i className="fds-glyphs-cross3"
                                onClick={() => {
                                    setFilterIp((prev: any) => {
                                        delete prev[key];
                                        return { ...prev };
                                    });
                                    setCurrentPage(1);
                                }} />
                        </Badge>
                    })}
                    <a href="/#" className='clearAll' onClick={(ev) => {
                        ev.preventDefault();
                        setFilterIp({});
                        setCurrentPage(1);
                    }}>
                        <small>Clear All Filters</small>
                    </a>
                </div>
            }
            <div className="arcciCorpWrap draStatusResults fds-table-wrap">
                <Table className='fds-table table-striped'>
                    <SearchSortHeader
                        headers={[...headers]}
                        sortKey={sortKey}
                        sortOrder={sortOrder}
                        onSort={(key: any) => {
                            const newOrder = key.toLowerCase() === sortKey.toLowerCase() ? (sortOrder === "ascn" ? "desc" : "ascn") : "ascn";
                            setSortOrder(newOrder);
                            setSortKey(key);
                        }}
                        search={true}
                        onChange={(obj: any) => {
                            setFilterIp(obj);
                            setCurrentPage(1);
                        }}
                        filterIp={filterIp}
                    />
                    {results?.length > 0 ?
                        <tbody>
                            {
                                results?.slice((currentPage - 1) * itemsPerPage, (currentPage - 1) * itemsPerPage + itemsPerPage)
                                    .map((r: any, ri: number) => {
                                        return (
                                            <tr className='ciRow' role="button" key={ri} data-testid={"p1CiRow" + ri}
                                                onClick={(ev: any) => {
                                                    ev.preventDefault();
                                                    /*const rpltr = window.document.getElementById("reportPageLeftToggleRef");
                                                    if (rpltr) {
                                                        const cl = rpltr?.classList;
                                                        cl?.remove("static");
                                                        cl?.add("min");
                                                    }*/
                                                    if (props.detailedData?.length > 0) {
                                                        const draObjs = [...props.detailedData].filter((draObj: any) => draObj.DRA_NUMBER === r.DRA_NUMBER);
                                                        if (draObjs.length > 0) {
                                                            setDetailedObject({ ...draObjs[0] });
                                                        }
                                                    } else {
                                                        setDetailedObject({ ...r });
                                                    }
                                                }}
                                            >
                                                {[...headers].map((header: any, hi: number) => {
                                                    return (<td key={hi} className={header.filter.type === "number" ? "right" : header.filter.position || "left"}>
                                                        {header.filter.tooltip ?
                                                            <OverlayTrigger
                                                                placement="auto"
                                                                overlay={<Tooltip>{header.filter.tooltip(r[header.key])}</Tooltip>}
                                                            >
                                                                <span>{FDS.valueOrDash(r.AGREEMENT_STATUS || "Approved")}</span>
                                                            </OverlayTrigger> :
                                                            header.filter.innerHTML ?
                                                                <>
                                                                    {header.filter.innerHTML(r[header.key])}
                                                                </> :
                                                                <>
                                                                    {header.filter.type === "number" ?
                                                                        FDS.numberFormatTxt(r[header.key], 2, header.filter?.symbol || "") :
                                                                        FDS.valueOrDash(r[header.key])
                                                                    }
                                                                </>
                                                        }
                                                    </td>)
                                                })}
                                            </tr>
                                        )
                                    })
                            }
                        </tbody> : <div className='p-3' style={{ height: "350px" }}>No data found for the filter criteria.</div>
                    }
                </Table>
                {results?.length > 0 &&
                    <div className="pagination pagination-container d-flex align-items-center justify-content-between p-2 ">
                        <p className='m-0'>
                            Records
                            <b> {FDS.numberFormat((currentPage - 1) * itemsPerPage + 1, 0, null)}</b> -
                            <b> {FDS.numberFormat((currentPage - 1) * itemsPerPage + itemsPerPage < (results?.length || 0) ? (currentPage - 1) * itemsPerPage + itemsPerPage : (results?.length || 0), 0, null)}</b> of
                            <b> {FDS.numberFormat(results?.length || 0, 0, null)}</b>
                        </p>
                        <DynamicPagination className='mb-0' currentPage={currentPage} itemPerPage={itemsPerPage} totalItems={results?.length || 0} onPageChange={(page: number) => setCurrentPage(page)} />
                    </div>
                }
            </div>
            {Object.keys(detailedObject).length > 0 &&
                <div className='ciDetailWrap'>
                    <div className="close" onClick={() => setDetailedObject({})}>
                        <i className='fds-glyphs-cross2' />
                    </div>
                    <Row className='pb-3'>
                        <Col>
                            <h1 className='heading-lg pb-3 mb-2'>DRA Details</h1>
                            <h2>{detailedObject.DRA_NUMBER}{detailedObject.ADC_CORP_NM && <span> - {detailedObject.ADC_CORP_NM}</span>}</h2>
                            {detailedObject.ADC_AGENCY_NAME && <div>{detailedObject.ADC_AGENCY_NAME}</div>}
                            {detailedObject.AGENCY_CONTACT_EMAIL__C && <div>{detailedObject.AGENCY_CONTACT_EMAIL__C}</div>}
                        </Col>
                    </Row>
                    <Row className='pt-3'>
                        <Col md={4}>
                            <Card className='p-3'>
                                <h2>DRA Status</h2>
                                <div>
                                    <ul className='tickList'>
                                        <li className='circle success'>
                                            <div>{detailedObject.AGREEMENT_STATUS}</div>
                                            <div><small>{detailedObject.approved_date}</small></div>
                                        </li>
                                        <li>
                                            <div>Ticketing Begin Date</div>
                                            <div><small>{FDS.valueOrDash(JSON.parse(detailedObject.TICKETING_BEGIN_DATE)?.start_date)}</small></div>
                                        </li>
                                        <li>
                                            <div>Approved Date</div>
                                            <div><small>{FDS.valueOrDash(detailedObject.approved_date)}</small></div>
                                        </li>
                                        <li>
                                            <div>DRA Sent Date</div>
                                            <div><small>{FDS.valueOrDash(detailedObject.dra_sent_date)}</small></div>
                                        </li>
                                        <li>
                                            <div>Data Status</div>
                                            <div><small>{FDS.valueOrDash(detailedObject.DATA_STATUS)}</small></div>
                                        </li>
                                        <li>
                                            <div>DRA Status</div>
                                            <div><small>{FDS.valueOrDash(detailedObject.AGREEMENT_STATUS)}</small></div>
                                        </li>
                                    </ul>
                                </div>
                            </Card>
                        </Col>
                        <Col md={8}>
                            <Card className='p-3' style={{ height: "100%" }}>
                                <h2>Data Status</h2>
                                {statusTable(detailedObject)}
                            </Card>
                        </Col>
                    </Row>
                </div>
            }
        </>
    )
}

export const ARCCIDataStatus = (props: ARCCIDataStatusProps) => {
    const [results, setResults]: any = useState({});
    const [summaryResults, setSummaryResults]: any = useState([]);

    const [modalData, setModalData]: any = useState({
        data: []
    });

    const [draHeaders]: any[] = useState([
        { key: "DRA_NUMBER", label: "DRA #", filter: { type: "text" } },
        { key: "ADC_AGENCY_NAME", label: "TMC", filter: { type: "text" } },
        { key: "ADC_CORP_NM", label: "CORPORATION", filter: { type: "text" } },
        {
            key: "AGREEMENT_STATUS", label: "STATUS", filter: {
                type: "select",
                options: [
                    { key: "Approved", value: "Approved" },
                    { key: "Pending Approval", value: "Pending Approval" },
                    { key: "Terminated", value: "Terminated" },
                    { key: "Unknown", value: "Unknown" }
                ],
                tooltip: asTooltip
            }
        },
        { key: "DAYS_IN_STATUS", label: "DAYS IN STATUS", filter: { type: "number" } },
        {
            key: "DATA_FLOW", label: "DATA FLOW", filter: {
                type: "select", options: [
                    { key: "G", value: "Processed, Data Flowing" },
                    { key: "Y", value: "Processed, Data < 2 weeks" },
                    { key: "R", value: "Processed, Data > 2 weeks" },
                    { key: "NA", value: "No Data Flowing" }
                ],
                innerHTML: draDataFlowRenderer,
                position: "center"
            }
        },
        {
            key: "dra_sent_date", label: "DATA FROM", help: "Ticket Date indicated on DRA from which data is required",
            filter: { type: "date", daysBefore: Math.round(+new Date() - +new Date("2018-01-01 00:00")) / (1000 * 60 * 60 * 24), daysAfter: 365 }
        },
        {
            key: "EXPIRATION_DATE", label: "EXPIRATION", help: "Expiration Date of the DRA as indicated, if applicable, on the DRA",
            filter: { type: "date", daysBefore: Math.round(+new Date() - +new Date("2018-01-01 00:00")) / (1000 * 60 * 60 * 24), daysAfter: 365 }
        }
    ]);

    const [dataHeaders]: any[] = useState([
        { key: "DRA_NUMBER", label: "DRA #", filter: { type: "text" } },
        { key: "ADC_CORP_NM", label: "CORPORATION", filter: { type: "text" } },
        { key: "ADC_AGENCY_NAME", label: "AGENCY", filter: { type: "text" } },
        {
            key: "DATA_FROM", label: "DATA FROM",
            filter: { type: "date", daysBefore: Math.round(+new Date() - +new Date("2018-01-01 00:00")) / (1000 * 60 * 60 * 24), daysAfter: 365 }
        },
        {
            key: "DATA_THRU", label: "DATA THRU",
            filter: { type: "date", daysBefore: Math.round(+new Date() - +new Date("2018-01-01 00:00")) / (1000 * 60 * 60 * 24), daysAfter: 365 }
        },
        { key: "tickets", label: "TICKETS", filter: { type: "number" } },
        { key: "total_percent_verified", label: "TOTAL % VERIFIED", filter: { type: "number", symbol: "%" } }
    ]);

    useEffect(() => {
        console.log("UE:1:REFFF...");
        setResults(JSON.parse(JSON.stringify(props.data || {})));
        //const htmlBody: any = document.querySelector("body");
        //htmlBody.style.paddingLeft = "0rem";
    }, [props.data]);

    useEffect(() => {
        console.log("UE:2:REFFF...");
        setSummaryResults([...(results?.summary?.results || [])]);
    }, [results]);

    return (
        <div id="arccidsWrap">
            <Tabs className='topTabs pt-3'
                onClick={(ev: any) => {
                    ev.preventDefault();
                    if (ev.target.innerHTML === "Report Builder") {
                        document.querySelector(".filtersToggleBtn")?.classList.add("hide");
                        document.querySelector(".floatTitle")?.classList.add("hide");
                    } else {
                        document.querySelector(".filtersToggleBtn")?.classList.remove("hide");
                        document.querySelector(".floatTitle")?.classList.remove("hide");
                    }
                    //setFilterIp({});
                }}
                onChange={(ev: any) => ev.preventDefault()}
            >
                <Tab className='pt-3' eventKey={"A"} title={"DRA Status"} onClick={(ev: any) => ev.preventDefault()} onChange={(ev: any) => ev.preventDefault()}>
                    {summaryResults?.length > 0 &&
                        <Row className='pb-3'>
                            <Col className='draChart' md={3}>
                                <FDS.DonutChart
                                    data={summaryResults}
                                    id="draStatus"
                                    keys={{
                                        label: 'AGREEMENT_STATUS',
                                        value: 'count'
                                    }}
                                    showTotal
                                    style={{
                                        width: 460,
                                        height: 460
                                    }}
                                    theme={{
                                        colors: [
                                            '#11B7D2',
                                            '#3CB53E',
                                            '#D08F12',
                                            '#625E89',
                                            '#3B3953',
                                            '#2D2B3F'
                                        ],
                                        darkColors: [
                                            '#000000',
                                            '#000000',
                                            '#000000',
                                            '#000000',
                                            '#000000',
                                            '#000000'
                                        ]
                                    }}
                                    hideLegends={true}
                                    hideToggleButton={true}
                                    hideDownloadButton={true}
                                    title=""
                                    totalRecords={summaryResults.length}
                                    totalText="DRAs"
                                    totalFirst
                                    withSimpleTooltip
                                />
                            </Col>
                            <Col md={9}>
                                <Row>
                                    <Col>
                                        <h1 className='heading-lg mb-3'>DRA Status</h1>
                                    </Col>
                                </Row>
                                <Row className='pb-3'>
                                    <Col>
                                        <Card className="mb-3">
                                            <CardBody>
                                                <CardTitle>
                                                    {FDS.numberFormatTxt(summaryResults.reduce((total: number, obj: any) => total + obj.approved_dras, 0) || 0)}
                                                </CardTitle>
                                                <CardText>
                                                    Approved DRAs
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col>
                                        <Card className="mb-3">
                                            <CardBody>
                                                <CardTitle>
                                                    {FDS.numberFormatTxt(summaryResults.reduce((total: number, obj: any) => total + obj.pending_approval_30days, 0) || 0)}
                                                </CardTitle>
                                                <CardText>
                                                    Pending Approval &gt;30 days
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Card className="mb-3">
                                            <CardBody>
                                                <CardTitle>
                                                    {FDS.numberFormatTxt(summaryResults.reduce((total: number, obj: any) => total + obj.approved_in_current_year, 0) || 0)}
                                                </CardTitle>
                                                <CardText>
                                                    Approved in Current Year
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col>
                                        <Card className="mb-3">
                                            <CardBody>
                                                <CardTitle>
                                                    {FDS.numberFormatTxt(summaryResults.reduce((total: number, obj: any) => total + obj.approved_in_last_7_days, 0) || 0)}                                                </CardTitle>
                                                <CardText>
                                                    Approved in last 7 days
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col>
                                        <Card className="mb-3">
                                            <CardBody>
                                                <CardTitle>
                                                    {FDS.numberFormatTxt(summaryResults.reduce((total: number, obj: any) => total + obj.expiring_30_days, 0) || 0)}                                                </CardTitle>
                                                <CardText>
                                                    Expiring within 30 days
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    }
                    {
                        results?.dra?.results?.length > 0 &&
                        <DPTable headers={[...draHeaders]} data={[...(results?.dra?.results || [])]} sortKey={draHeaders[0].key} itemsPerPage={10} />
                    }
                </Tab>
                <Tab eventKey={"B"} title={"Data Status"}>
                    {results?.data?.results?.length > 0 &&
                        <>
                            <h1 className='heading-lg ps-3 pt-3 pb-3'>Data Status</h1>
                            <DPTable headers={[...dataHeaders]} data={[...(results?.data?.results || [])]} detailedData={[...(results?.dra?.results || [])]} sortKey={dataHeaders[0].key} itemsPerPage={10} />
                        </>
                    }
                </Tab>
                <Tab eventKey={"C"} title={"Report Builder"}>
                    <h1 className='heading-lg ps-3 pt-3 pb-3'>Report Builder</h1>
                    <Row>
                        <Col>
                            <Link to={`/reports/ARCCIDSCOMP`} className="nav-link no-style">
                                <Card className="p-3 mb-3">
                                    <CardBody>
                                        <CardTitle>
                                            Competitive
                                        </CardTitle>
                                        <CardText>
                                            Subtext Label
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Link>
                        </Col>
                        <Col>
                            <Link to={`/reports/ARCCIDSAIR`} className="nav-link no-style">
                                <Card className="p-3 mb-3">
                                    <CardBody>
                                        <CardTitle>
                                            Airline
                                        </CardTitle>
                                        <CardText>
                                            Subtext Label
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Link>
                        </Col>
                        <Col>
                            <Link to={`/reports/ARCCIDSDD`} className="nav-link no-style">
                                <Card className="p-3 mb-3">
                                    <CardBody>
                                        <CardTitle>
                                            DRA & Data
                                        </CardTitle>
                                        <CardText>
                                            Subtext Label
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Link>
                        </Col>
                    </Row>
                </Tab>
            </Tabs>

            {modalData?.data?.length > 0 &&
                <Modal
                    className="tcvModal"
                    show={modalData?.data?.length > 0}
                    onHide={() => setModalData({ data: [] })}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Tour Code Validation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FDS.BarChart
                            id="TCV_Chart"
                            title=""
                            orientation="V"
                            sideBySide={true}
                            data={modalData?.data || []}
                            totalRecords={modalData?.data?.length || 0}
                            labels={{
                                x: "Timeframe",
                                y: "Ticket Count"
                            }}
                            keys={{
                                label: "ISSUE_MONTH",
                                values: ["TICKETS_SENT", "TICKETS_NOT_RECEIVED"],
                                legends: ["Tickets Sent", "Tickets Not Received"],
                                symbol: ""
                            }}
                            style={{
                                width: 600,
                                height: 500
                            }}
                            sortKey={""}
                        />
                    </Modal.Body>
                </Modal>
            }
        </div>
    );
}