import React, { useState, useRef } from 'react'
import { Alert, Button } from 'react-bootstrap';

const Dismissable = ({ obj }: any) => {
    const dismissableRef = useRef() as React.MutableRefObject<HTMLInputElement>;
    const [showAlert, setShowAlert] = useState(true);
    const { messages, title } = obj

    const handleDismissableClose = (title: string) => {
        dismissableRef.current.style.opacity = "0";
        if (!localStorage.getItem("REF:flashChecked")) {
            localStorage.setItem('REF:flashChecked', JSON.stringify([title]))
        } else {
            const msgStatus = JSON.parse(localStorage.getItem("REF:flashChecked")!)
            msgStatus.push(title)
            localStorage.setItem('REF:flashChecked', JSON.stringify(msgStatus))
        }

        setInterval(() => {
            setShowAlert(false)
        }, 300)

    }

    if (!showAlert) return null

    return (
        <Alert className='homeAlert' ref={dismissableRef} variant="primary" style={{ paddingLeft: "3.8rem" }} onClose={() => handleDismissableClose(title)}>
            <div className="icon"><i className="fds-glyphs-info2"></i></div>
            <div className="text">{messages}</div>
            <Button
                className="close btn-link"
                aria-label="Close Alert"
                onClick={() => { handleDismissableClose(title) }}
                onKeyUp={(e) => { if (e.key === "Enter") { handleDismissableClose(title) } }}
            >
                <i className="fds-glyphs fds-glyphs-clear"></i>
            </Button>
        </Alert>
    )
}

export default Dismissable