import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { downloadReport } from '../../store/actions';

export const DownloadPanel = (props: any) => {
    const dispatch = useDispatch();
    const notificationRef = useSelector((store: any) => store.notificationRef);

    useEffect(() => {
        if (Object.keys(props.reportIpRef).length > 1 && props.reportIpRef.action === "download" && notificationRef?.body?.notificationType === "reportExport" && notificationRef?.body?.reportName?.length > 0) {
            dispatch(downloadReport(notificationRef?.body?.reportName));
            dispatch({ type: "REPORT_IP", data: {} });
            dispatch({ type: "LOADING", data: "" });

            setTimeout(() => {
                if (notificationRef?.body?.description?.toUpperCase()?.indexOf("ZERO") === 0) {
                    dispatch({ type: "STATUS", data: { type: "WARNING", title: "Notification", message: `Report ${notificationRef?.body?.reportName} has no available data for the filter criteria.` } });
                } else {
                    dispatch({ type: "STATUS", data: { type: "SUCCESS-WAIT", title: "Notification", message: `Report ${notificationRef?.body?.reportName} downloaded successfully. Check your browser's download history for the file.` } });
                }
                dispatch({ type: "N_STATUS", data: {} });
            }, 1000);

        }
    }, [props.reportIpRef, notificationRef, dispatch])

    return (<></>);
}