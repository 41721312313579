import { w3cwebsocket as W3CWebSocket } from 'websocket';

declare const window: any;

let webSocket: W3CWebSocket;

export const getNotificationsAction = (isConnect: boolean) => {
    return (dispatch: any) => {
        return new Promise((resolve: any, reject: any) => {
            if ((webSocket && webSocket?.onclose !== null) || !isConnect) {
                webSocket.close();
            } else if (webSocket && webSocket?.onclose === null) {
                return;
            }
            if (isConnect) {
                webSocket = new W3CWebSocket(window.ENV.wsDomain);
                webSocket.onopen = () => {
                    webSocket.onmessage = (event: any) => {
                        if (!JSON.parse(event.data).message) {
                            try {
                                if (JSON.parse(event.data).type === 'unread') {
                                    const body = JSON.parse(event.data).body;
                                    body.forEach((item: any, i: number) => {
                                        if (item?.notification?.notificationType === "reportExport") {
                                            dispatch({ type: "NOTIFICATIONS", data: item?.notification });
                                        }
                                    });
                                } else {
                                    const body = JSON.parse(JSON.parse(event.data).body);
                                    const statusCode = JSON.parse(JSON.parse(event.data)?.statusCode)
                                    const pos = ["reportExport", "requestFailed"].indexOf(body?.notificationType);
                                    if (pos >= 0) {
                                        /* istanbul ignore next */
                                        setTimeout(() => {
                                            if (body?.reportName?.indexOf(window.document.getElementById("reportNameRef")?.innerHTML) < 0) {
                                                dispatch({
                                                    type: "NOTIFICATIONS",
                                                    data: {
                                                        ...body,
                                                        status: pos === 0 ? "info" : "danger"
                                                    }
                                                });
                                                if (window.document.getElementById('audio')) {
                                                    window.document.getElementById('audio').volume = 0.2;
                                                    window.document.getElementById('audio').play();
                                                }
                                            }
                                        }, 8000);
                                    }
                                    dispatch({ type: "N_STATUS", data: { type: "INFO", title: "Notification", message: "You have a new notification.", body: body, statusCode: statusCode } });
                                }
                            } catch (err: any) {
                                dispatch({ type: "STATUS", data: { type: "DANGER", title: "Error", message: `Unable to process websocket data. Please try again. (${err.message})` } });
                                reject(err);
                            }
                        }
                        resolve();
                    };

                    webSocket.onclose = function () {
                        if (isConnect) {
                            dispatch(getNotificationsAction(isConnect));
                        }
                    };

                    webSocket.send(`{ 
                        "action": "sendMessage", 
                        "token": "Bearer ${localStorage.getItem('bearer')}"
                    }`);
                };
                webSocket.onerror = function (err: any) {
                    webSocket.close();
                    dispatch({ type: "STATUS", data: { type: "DANGER", title: "Error", message: `Unable to connect websocket. Please try again.` } });
                };
            }
        })
    }
};