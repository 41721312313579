import * as FDS from "@arctravel/react-fds/lib";
declare const window: any;

export const ARCCITemplate: any = {
    reportName: "ARCCI",
    onLoadRun: true,
    menu: "left-small",
    info: "",
    lists: [{
        label: "Query Filters",
        colorName: "",
        wraps: [{
            name: "Period Date Range",
            showName: true,
            type: 'option',
            condition: 'OR',
            help: "Data is refreshed weekly",
            fields: [{
                name: "day_report_period",
                tab: "By Day",
                label: "By Day or Range",
                type: "date",
                selectsRange: true,
                startDate: [FDS.dateToText(new Date(new Date().setDate(new Date().getDate() - 13)), "/", true, false)],
                endDate: [FDS.dateToText(new Date(new Date().setDate(new Date().getDate() - 7)), "/", true, false)],
                show: true
            }, {
                name: "week_report_period",
                tab: "By Week",
                label: "By Week",
                type: "date",
                subType: "week",
                selectsRange: true,
                startDate: [FDS.dateToText(new Date(new Date().setDate(new Date().getDate() - 6)), "/", true, false)],
                endDate: [FDS.dateToText(new Date(), "/", true, false)],
                show: false
            }, {
                name: "month_report_period",
                tab: "By Month",
                label: "By Month or Range",
                type: "monthYearPicker",
                selectsRange: true,
                startDate: [(new Date().getMonth() + 1 + "/" + new Date().getFullYear())],
                endDate: [(new Date().getMonth() + 1 + "/" + new Date().getFullYear())],
                show: false
            }, {
                name: "year_report_period",
                tab: "By Year",
                label: "By Year",
                type: "yearPicker",
                startDate: [new Date().getFullYear() + ""],
                endDate: [],
                show: false
            }]
        }, {
            name: "corporation",
            showName: false,
            fields: [{
                name: "corporation",
                label: "Corporation",
                rLabel: "Corporation",
                help: "Enter one or more corporation ids",
                type: "typeahead",
                matchedOnly: true,
                value: [],
                labelKey: "description",
                codeKey: "code",
                minChar: 0,
                url: window.ENV.references.corporate,
                outputLabel: true,
                options: [],
                localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.corporate) || '[]'),
                placeholder: "Corporation-Id"
            }]
        }, {
            name: "tmc",
            showName: false,
            fields: [{
                name: "tmc",
                label: "TMC",
                rLabel: "tmc",
                help: "Enter one or more agent ids",
                type: "typeahead",
                matchedOnly: true,
                value: [],
                labelKey: "description",
                codeKey: "code",
                minChar: 0,
                url: window.ENV.references.agent,
                outputLabel: true,
                options: [],
                localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.agent) || '[]'),
                placeholder: "Agent-Id"
            }]
        }, {
            name: "account_type",
            showName: false,
            fields: [{
                name: "account_type",
                label: "Account Type",
                help: "Select one or a combination of account types",
                type: "typeahead",
                labelKey: "value",
                codeKey: "key",
                hideKey: true,
                minChar: 0,
                color: 'c',
                matchedOnly: true,
                selectAll: true,
                value: [],
                options: [
                    { key: "Global", value: "Global", checked: true },
                    { key: "MCTA", value: "MCTA", checked: true },
                    { key: "Other", value: "Other", checked: true },
                    { key: "Blank", value: "Blank", checked: true }
                ]
            }]
        }, {
            name: "acc_status",
            showName: false,
            fields: [{
                name: "acc_status",
                label: "Status",
                help: "Select one or a combination of account status types",
                type: "typeahead",
                labelKey: "value",
                codeKey: "key",
                hideKey: true,
                minChar: 0,
                color: 'c',
                matchedOnly: true,
                selectAll: true,
                value: [],
                options: [
                    { key: "1 - Closed", value: "Closed", checked: true },
                    { key: "2 - No Data", value: "No Data", checked: true },
                    { key: "3 - With Data", value: "With Data", checked: true },
                ]
            }]
        }]
    }],
    chartsLists: [
        {
            label: "",
            wraps: [
                {
                    name: "performance_metrics",
                    label: "Performance Metrics",
                    showName: true,
                    fields: [{
                        name: "summary",
                        label: "Summary",
                        help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                        type: "SummaryCards",
                        keys: {
                            label: "summary",
                            values: [
                                "count",
                                "corporate_count",
                                "drf_count_us_pos_data",
                                "drf_count_non_us_pos_data"
                            ],
                            vLabels: ["DRA Count", "Corporate Count", "DRA Count with U.S. POS Data", "DRA Count with Non-U.S. POS Data"],
                            symbol: ["", "", "%", "%"]
                        }
                    }]
                }, {
                    name: "drf",
                    label: "DRA Statistics",
                    showName: true,
                    fields: [{
                        name: "drfsonfile",
                        label: "DRAs on File by Segment",
                        help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                        type: "BarChart",
                        orientation: "V",
                        labels: { x: "Status", y: "Count" },
                        keys: {
                            label: "acc_status",
                            values: ["count"],
                            symbol: "",
                            sort: ""
                        },
                        spacing: 6
                    }, {
                        name: "drfSubmitted",
                        label: "DRAs Submitted",
                        help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                        type: "BarChart",
                        orientation: "V",
                        labels: { x: "Submitted", y: "Count" },
                        keys: {
                            label: "submit_month",
                            values: ["count"],
                            symbol: "",
                            sort: ""
                        },
                        spacing: 6
                    }]
                }
            ]
        }
    ]
};

