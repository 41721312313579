import React, { MouseEventHandler } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

type SortOrder = "ascn" | "desc"

const SortButton = ({ sortOrder, columnKey, sortKey, columnHeader, help, position, onClick }: {
    sortOrder: SortOrder,
    columnKey: string,
    sortKey: string,
    columnHeader: string,
    help?: string
    position?: string,
    onClick: MouseEventHandler
}) => {
    return (
        <th className={position} onClick={onClick}>
            {columnHeader}
            {columnKey !== "action" &&
                sortKey === columnKey && sortOrder &&
                (
                    sortKey === columnKey && sortOrder === "desc" ?
                        <i className="fds-glyphs-arrow-down5 ms-10" /> :
                        <i className="fds-glyphs-arrow-up5 ms-10" />
                )
            }
            {help &&
                <OverlayTrigger
                    placement="auto"
                    overlay={<Tooltip>{help}</Tooltip>}
                >
                    <span className="ms-2 fds-glyphs-info infoTxt"></span>
                </OverlayTrigger>
            }
        </th>
    )
}

export default SortButton