declare const window: any;

const GLOBALDATA = "GLOBAL"
const agencyEntity = "AGENCY"
const airlineEntity = "AIRLINE"
const financialEntity = "THIRDPARTY-FINANCIAL"
const nonFinancialEntity = "THIRDPARTY-NON-FINANCIAL"
const ndcSubscription = "NDC"
const aggt = "AGGREGATION"

// hideFor : Entity
// showFor: DataSource

export const JOURNEYTemplate: any = {
    reportName: "JOURNEY",
    info: "Create a report using the query filters below by using the dropdown lists, the type & search feature to find suggestions, or manually enter one or more criteria separated by commas. Use the Enter key to confirm your entries before moving to the next query filter.",
    lists: [
        {
            label: "Query Filters",
            colorName: "",
            wraps: [{
                name: "airline",
                showName: false,
                excludable: true,
                hideFor: { entity: [financialEntity, nonFinancialEntity] },
                checks: aggt,
                accordianWraps: ["airline", "airline_role", "Trip Origin", "Trip Destination", "countriesOfSale", "Markets"],
                accordianLabel: "General",
                mergeWraps: ["airline", "airline_role"],
                fields: [{
                    name: "airline",
                    label: "Airline(s)",
                    rLabel: "Airline code",
                    help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                    type: "typeahead",
                    matchedOnly: true,
                    value: [],
                    labelKey: "description",
                    codeKey: "code",
                    minChar: 0,
                    url: window.ENV.references.airline,
                    outputLabel: true,
                    options: [],
                    localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.airline) || '[]'),
                    placeholder: "Enter Airline(s)"
                }]
            }, {
                name: "airline_role",
                showName: false,
                hideFor: { entity: [financialEntity, nonFinancialEntity] },
                isAccordianWrap: true,
                isMerged: true,
                fields: [{
                    name: "airline_role",
                    label: "Specify Airline Type",
                    rLabel: "Specified Airlines Are",
                    help: "Select a single airline type to correspond with the airline criteria entered above.",
                    type: "select",
                    noSelect: true,
                    value: 'dom_mkt_al',
                    options: [
                        { key: "dom_mkt_al", value: "Dominant Marketing Airlines", checked: true },
                        { key: "dom_opt_al", value: "Dominant Operating Airlines", checked: true },
                        { key: "tkt_al", value: "Ticketing Airlines", checked: true },
                        { key: "poo_mkt_al", value: "Point of Origin Marketing Airlines", checked: true },
                        { key: "mkt_al_any_segment", value: "Marketing Airlines on any Segment", checked: true },
                        { key: "opt_al_any_segment", value: "Operating Airlines on any Segment", checked: true }
                    ]
                }]
            }, {
                name: "Trip Origin",
                showName: true,
                type: 'option',
                condition: 'AND',
                requiredGroup: 1,
                excludable: true,
                isAccordianWrap: true,
                mergeWraps: ["Trip Origin", "Trip Destination"], //wrap.name
                fields: [{
                    name: "org",
                    tab: "Airport",
                    label: "By Airport",
                    rLabel: "Trip Origin Codes",
                    type: "typeahead",
                    matchedOnly: true,
                    value: [],
                    labelKey: "description",
                    codeKey: "code",
                    color: "ap",
                    minChar: 2,
                    show: true,
                    disableFor: { entity: [financialEntity] },
                    url: window.ENV.references.airport,
                    options: [],
                    localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.airport) || '[]'),
                    placeholder: "Enter Origin(s)"
                }, {
                    name: "o_region",
                    tab: "Region",
                    label: "By Region/State",
                    rLabel: "Trip Origin Region",
                    type: "tree",
                    restrictTCs: true,
                    value: [],
                    labelKey: "name",
                    codeKey: "code",
                    color: "r",
                    minChar: 2,
                    show: false,
                    disableFor: { entity: [] },
                    url: window.ENV.references.region,
                    options: [],
                    localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.region) || '[]'),
                    placeholder: "Enter Origin(s)"
                }, {
                    name: "o_city_cd",
                    tab: "City",
                    label: "By City",
                    rLabel: "Trip Origin City",
                    type: "typeahead",
                    matchedOnly: true,
                    value: [],
                    labelKey: "description",
                    codeKey: "code",
                    color: "c",
                    minChar: 1,
                    show: false,
                    disableFor: { entity: [financialEntity] },
                    url: window.ENV.references.city,
                    options: [],
                    localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.city) || '[]'),
                    placeholder: "Enter Origin(s)"
                }]
            }, {
                name: "Trip Destination",
                showName: true,
                type: 'option',
                condition: 'AND',
                requiredGroup: 1,
                excludable: true,
                isAccordianWrap: true,
                isMerged: true, //Setting TRUE merge into mergeWraps when name is in mergeWraps.
                fields: [{
                    name: "dst",
                    tab: "Airport",
                    label: "By Airport",
                    rLabel: "Trip Destination Codes",
                    type: "typeahead",
                    matchedOnly: true,
                    value: [],
                    labelKey: "description",
                    codeKey: "code",
                    minChar: 2,
                    color: 'ap',
                    show: true,
                    disableFor: { entity: [financialEntity] },
                    url: window.ENV.references.airport,
                    options: [],
                    localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.airport) || '[]'),
                    placeholder: "Enter Destination(s)"
                }, {
                    name: "d_region",
                    tab: "Region",
                    label: "By Region/State",
                    rLabel: "Trip Destination Region",
                    type: "tree",
                    restrictTCs: true,
                    value: [],
                    labelKey: "name",
                    codeKey: "code",
                    minChar: 2,
                    color: 'r',
                    show: false,
                    disableFor: { entity: [] },
                    url: window.ENV.references.region,
                    options: [],
                    localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.region) || '[]'),
                    placeholder: "Enter Destination(s)"
                }, {
                    name: "d_city_cd",
                    tab: "City",
                    label: "By City",
                    rLabel: "Trip Destination City",
                    type: "typeahead",
                    matchedOnly: true,
                    value: [],
                    labelKey: "description",
                    codeKey: "code",
                    minChar: 1,
                    color: 'c',
                    show: false,
                    disableFor: { entity: [financialEntity] },
                    url: window.ENV.references.city,
                    options: [],
                    localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.city) || '[]'),
                    placeholder: "Enter Destination(s)"
                }]
            }, {
                name: "countriesOfSale",
                showName: false,
                isAccordianWrap: true,
                mergeWraps: ["countriesOfSale", "Markets"],
                fields: [{
                    name: "pos_country",
                    label: "Countries Of Sale",
                    rLabel: "Countries of Sale",
                    type: "typeahead",
                    matchedOnly: true,
                    value: [],
                    labelKey: "name",
                    codeKey: "code",
                    minChar: 0,
                    disabled: true,
                    enableFor: { dataSource: GLOBALDATA },
                    show: true,
                    url: window.ENV.references.country,
                    options: [],
                    localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.country) || '[]')
                }]
            }, {
                name: "Markets",
                showName: true,
                requiredGroup: 1,
                help: "Enter each market by concatenating two airport codes, i.e. DCAJFK. Multiple markets can be entered separated by commas.",
                hideFor: { entity: [financialEntity] },
                isAccordianWrap: true,
                isMerged: true,
                fields: [{
                    name: "markets",
                    label: "Markets",
                    hideLabel: true,
                    type: "typeahead",
                    matchedOnly: false,
                    value: [],
                    labelKey: "name",
                    codeKey: "code",
                    minChar: 0,
                    color: 'ap',
                    show: true,
                    store: "groupingsDataRef",
                    options: [],
                    localStorage: JSON.parse(localStorage.getItem("REF:apap") || '[]'),
                    canEmpty: true,
                    placeholder: "Enter Market(s)"
                }]
            }, {
                name: "Agency",
                showName: true,
                type: 'option',
                condition: 'OR',
                excludable: true,
                disableEmpty: true,
                hideFor: { entity: [financialEntity, nonFinancialEntity] },
                help: "Enter complete 8-digit agency or HOL numbers, separated by commas. Leave blank to view agencies located in a city, state, postal code or region.",
                accordianWraps: ["Agency", "Agency Information", "Agency Region Information"],
                accordianLabel: "Agency Information",
                lookup: "agency",
                fields: [{
                    name: "agency",
                    tab: "Agency",
                    label: "By Agency",
                    rLabel: "Agency",
                    type: "typeahead",
                    value: [],
                    labelKey: "description",
                    codeKey: "code",
                    color: 'ap',
                    minChar: 0,
                    show: true,
                    outputLabel: true,
                    isAgencyTop: true,
                    options: [],
                    url: window.ENV.references.agency,
                    localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.agency) || '[]'),
                    placeholder: "Enter Agency Code(s) / Group(s)"
                }, {
                    name: "hol",
                    tab: "Home Office Location",
                    label: "By Home Office Location",
                    rLabel: "Home Office Location",
                    type: "typeahead",
                    value: [],
                    labelKey: "description",
                    codeKey: "code",
                    color: 'c',
                    show: false,
                    outputLabel: true,
                    isAgencyTop: true,
                    minChar: 0,
                    options: [],
                    url: window.ENV.references.agency,
                    localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.agency) || '[]'),
                    placeholder: "Enter Home Office Location Code(s) / Group(s)"
                }]
            }, {
                name: "Agency Information",
                showName: false,
                hideFor: { entity: [financialEntity, nonFinancialEntity] },
                isAccordianWrap: true,
                fields: [{
                    name: "agency_city",
                    label: "City",
                    type: "text",
                    value: ""
                }, {
                    name: "agency_postal",
                    label: "Postal Code",
                    type: "text",
                    value: ""
                }]
            }, {
                name: "Agency Region Information",
                showName: false,
                hideFor: { entity: [financialEntity, nonFinancialEntity] },
                isAccordianWrap: true,
                fields: [{
                    name: "agency_region",
                    label: "State / Region",
                    type: "tree",
                    value: [],
                    labelKey: "name",
                    codeKey: "code",
                    color: 'r',
                    url: window.ENV.references.region,
                    options: [],
                    localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.region) || '[]')
                }]
            }, {
                name: "date_type",
                showName: false,
                accordianWraps: ["date_type", "Report Period"],
                accordianLabel: "Date Information",
                fields: [{
                    name: "date_type",
                    label: "Date Type",
                    help: "Select either the purchase or travel type to correspond with the date period below.",
                    type: "select",
                    noSelect: true,
                    value: 'travel_date',
                    options: [
                        { key: "travel_date", value: "Travel Date(s)", checked: true },
                        { key: "purchase_date", value: "Purchase Date(s)", checked: true }
                    ]
                }]
            }, {
                name: "Report Period",
                help: "FROM date should be previous or current to the TO date",
                showName: true,
                type: 'option',
                condition: 'OR',
                isAccordianWrap: true,
                fields: [{
                    name: "month_report_period",
                    tab: "By Month",
                    label: "By Month or Range",
                    type: "monthYearPicker",
                    selectsRange: true,
                    startDate: [(new Date().getMonth() + 1 + "/" + new Date().getFullYear())],
                    endDate: [(new Date().getMonth() + 1 + "/" + new Date().getFullYear())],
                    show: true
                }, {
                    name: "quarter_report_period",
                    tab: "By Quarter",
                    label: "By Quarter or Range",
                    type: "quarterPicker",
                    selectsRange: true,
                    startDate: ["Q" + (Math.ceil((new Date().getMonth() + 1) / 3) + "/" + new Date().getFullYear())],
                    endDate: ["Q" + (Math.ceil((new Date().getMonth() + 1) / 3) + "/" + new Date().getFullYear())],
                    show: false
                }, {
                    name: "summary_report_period",
                    tab: "12M Sum",
                    label: "12 Month Summary Ending",
                    type: "monthYearPicker",
                    startDate: [(new Date().getMonth() + 1 + "/" + new Date().getFullYear())],
                    endDate: [''],
                    show: false
                }, {
                    name: "compare_m_report_period",
                    tab: "Compare(M)",
                    label: "Compare Months",
                    compare: true,
                    type: "monthYearPicker",
                    selectsRange: true,
                    startDate: [
                        (new Date().getMonth() + 1 + "/" + new Date().getFullYear()),
                        (new Date().getMonth() + 1 + "/" + new Date().getFullYear())
                    ],
                    endDate: [
                        (new Date().getMonth() + 1 + "/" + new Date().getFullYear()),
                        (new Date().getMonth() + 1 + "/" + new Date().getFullYear())
                    ],
                    show: false
                }, {
                    name: "compare_q_report_period",
                    tab: "Compare(Q)",
                    label: "Compare Quarters",
                    compare: true,
                    type: "quarterPicker",
                    selectsRange: true,
                    startDate: [
                        "Q" + (Math.ceil((new Date().getMonth() + 1) / 3) + "/" + new Date().getFullYear()),
                        "Q" + (Math.ceil((new Date().getMonth() + 1) / 3) + "/" + new Date().getFullYear())
                    ],
                    endDate: [
                        "Q" + (Math.ceil((new Date().getMonth() + 1) / 3) + "/" + new Date().getFullYear()),
                        "Q" + (Math.ceil((new Date().getMonth() + 1) / 3) + "/" + new Date().getFullYear())
                    ],
                    show: false
                }, {
                    name: "compare_y_report_period",
                    tab: "Compare(Y)",
                    label: "Compare 2 Years",
                    compare: true,
                    type: "yearPicker",
                    startDate: [(new Date().getFullYear() - 1) + "", new Date().getFullYear() + ''],
                    endDate: [],
                    show: false
                }]
            }, {
                name: "Service Class",
                showName: false,
                fields: [{
                    name: "cabin_class",
                    label: "Service Class",
                    type: "typeahead",
                    matchedOnly: true,
                    selectAll: true,
                    value: [],
                    minChar: "0",
                    labelKey: "value",
                    codeKey: "key",
                    options: [
                        { key: "First Class", value: "First Class", checked: true },
                        { key: "Business Class", value: "Business Class", checked: true },
                        { key: "Premium/Full Economy Class", value: "Premium/Full Economy Class", checked: true },
                        { key: "Discount Economy Class", value: "Discount Economy Class", checked: true },
                        { key: "Other Classes", value: "Other Classes", checked: true }
                    ],
                    placeholder: "Enter Service Class(s)"
                }]
            }, {
                name: "Dominant Ticketing Designator",
                showName: false,
                fields: [{
                    name: "trip_dom_rbkd",
                    label: "Dominant Ticketing Designator",
                    rLabel: "Dominant Ticketing Designator",
                    type: "typeahead",
                    matchedOnly: true,
                    value: [],
                    minChar: "0",
                    labelKey: "value",
                    codeKey: "key",
                    selectAll: true,
                    options: [
                        { key: "A", value: "A", checked: true },
                        { key: "B", value: "B", checked: true },
                        { key: "C", value: "C", checked: true },
                        { key: "D", value: "D", checked: true },
                        { key: "E", value: "E", checked: true },
                        { key: "F", value: "F", checked: true },
                        { key: "G", value: "G", checked: true },
                        { key: "H", value: "H", checked: true },
                        { key: "I", value: "I", checked: true },
                        { key: "J", value: "J", checked: true },
                        { key: "K", value: "K", checked: true },
                        { key: "L", value: "L", checked: true },
                        { key: "M", value: "M", checked: true },
                        { key: "N", value: "N", checked: true },
                        { key: "O", value: "O", checked: true },
                        { key: "P", value: "P", checked: true },
                        { key: "Q", value: "Q", checked: true },
                        { key: "R", value: "R", checked: true },
                        { key: "S", value: "S", checked: true },
                        { key: "T", value: "T", checked: true },
                        { key: "U", value: "U", checked: true },
                        { key: "V", value: "V", checked: true },
                        { key: "W", value: "W", checked: true },
                        { key: "X", value: "X", checked: true },
                        { key: "Y", value: "Y", checked: true },
                        { key: "Z", value: "Z", checked: true }
                    ],
                    placeholder: "Enter Designator(s)"
                }]
            }, {
                name: "directionality",
                showName: false,
                fields: [{
                    name: "directionality",
                    label: "Directionality",
                    help: 'Select "Directional" to limit output to one direction, from origin(s) to destination(s). Select "Bi-directional" to display output for both directions of travel separately, or “Bi-directional Total” to sum both directions together. Select "Non-directional" to display the average from both directions of travel into one output row.',
                    type: "select",
                    noSelect: true,
                    value: 'DIRECTIONAL',
                    options: [
                        { key: "DIRECTIONAL", value: "Directional", checked: true },
                        { key: "NON_DIRECTIONAL", value: "Non-Directional", checked: true },
                        { key: "BI_DIRECTIONAL", value: "Bi-Directional", checked: true },
                        { key: "BI_DIRECTIONAL_TOTAL", value: "Bi-Directional Total", checked: true },
                    ]
                }]
            }, {
                name: "Transaction Type",
                showName: false,
                fields: [{
                    name: "transaction_code",
                    label: "Transaction Type",
                    type: "typeahead",
                    matchedOnly: true,
                    value: [],
                    minChar: "0",
                    labelKey: "value",
                    codeKey: "key",
                    selectAll: true,
                    options: [
                        { key: "E", value: "Exchange", checked: true },
                        { key: "O", value: "Old Ticket", checked: true, enableFor: { entity: [airlineEntity] } },
                        { key: "V", value: "Void", checked: true, enableFor: { dataSource: GLOBALDATA } },
                        { key: "R", value: "Refund", checked: true },
                        { key: "I", value: "Sales", checked: true }
                    ],
                    placeholder: "Enter Transaction Type(s)"
                }]
            }, {
                name: "ndc",
                showName: false,
                showFor: { subscription: ndcSubscription },
                checks: ndcSubscription,
                fields: [{
                    name: "ndc",
                    label: "NDC Indicator",
                    help: 'Include or exclude NDC Indicator.',
                    type: "select",
                    noSelect: false,
                    value: "",
                    options: [
                        { key: "Y", value: "Yes", checked: true },
                        { key: "N", value: "No", checked: true }
                    ]
                }]
            }, {
                name: "data_columns_to_report",
                showName: false,
                show: false,
                fields: [{
                    name: "data_columns_to_report",
                    label: "Data Columns To Report",
                    show: false,
                    type: "hidden",
                    value: 'FULL',
                    options: [{ key: "FULL", value: "FULL" }]
                }]
            }, {
                // }, {
                //     name: "data_columns_to_report",
                //     showName: false,
                //     fields: [{
                //         name: "data_columns_to_report",
                //         label: "Data Columns To Report",
                //         type: "select",
                //         noSelect: true,
                //         value: 'STANDARD',
                //         options: [
                //             { key: "MINIMAL", value: "Minimal", checked: true },
                //             { key: "STANDARD", value: "Standard", checked: true },
                //             { key: "FULL", value: "Full", checked: true }
                //         ]
                //     }]
                // }, {
                name: "Show Results",
                showName: false,
                fields: [{
                    name: "show_results",
                    label: "Show Results",
                    help: "Select “Per Period” to view data summarized by your selected time period increments. Select “Per Day” to view a per day average for your selected time period.",
                    type: "radio",
                    value: "PERIOD",
                    options: [
                        { key: 'PERIOD', value: 'Per Period' },
                        { key: 'PER_DAY', value: 'Per Day' },
                    ]
                }]
            }, {
                name: "Sort By",
                showName: false,
                hideFor: { entity: [financialEntity] },
                fields: [{
                    name: "sort_by",
                    label: "Sort By",
                    rLabel: "Sort By",
                    type: "select",
                    noSelect: true,
                    value: "trip",
                    options: [
                        { key: 'trip', value: 'Passenger Count', checked: true },
                        { key: 'airline', value: 'Airline', checked: true, disableFor: { entity: [nonFinancialEntity] } },
                        { key: 'airport', value: 'Airport', checked: true }
                    ]
                }]
            }, {
                name: "Output Options",
                help: "Select one or all options below for inclusion in the report for more detailed output.",
                showName: true,
                fields: [{
                    name: "showConfig",
                    label: "Select All Data Fields",
                    type: "toggleCheckbox",
                    withCheckbox: true,
                    checked: false,
                    collapse: true,
                    value: [
                        { show_org: true },
                        { show_dst: true },
                        { show_countries: false },
                        { show_cabin_class: false },
                        { show_connections: false },
                        { show_pos_country: false },
                        { show_monthly_breakdown: false },
                        { show_transaction: false },
                        { show_ticket_type: false },
                        { show_dom_mkt_al: true },
                        { show_dom_opt_al: false },
                        { show_agencies: false },
                        { show_agency_groups: false },
                        { show_airline_groups: false },
                        { show_source: false }
                    ],
                    options: [
                        { key: "show_org", value: "Origin Airport", rLabel: "Show Origin Airport", checked: true, disableFor: { entity: [financialEntity] } },
                        { key: "show_dst", value: "Destination Airport", rLabel: "Show Destination Airport", checked: true, disableFor: { entity: [financialEntity] } },
                        { key: "show_countries", value: "Countries", rLabel: "Show Countries", checked: true },
                        { key: "show_cabin_class", value: "Trip Class of Service", rLabel: "Show Trip Class of Service", checked: true },
                        { key: "show_connections", value: "Connections", rLabel: "Show Connections", checked: true, disableFor: { entity: [financialEntity] } },
                        { key: "show_pos_country", value: "Countries of Sale", rLabel: "Show Countries of Sale", checked: true },
                        { key: "show_monthly_breakdown", value: "Monthly Breakdown", rLabel: "Show Monthly Breakdown", checked: true },
                        { key: "show_transaction", value: "Transaction Code", rLabel: "Show Transaction Code", checked: true },
                        { key: "show_ticket_type", value: "Ticket Type", rLabel: "Show Ticket Type", checked: true, disableFor: { entity: [nonFinancialEntity, financialEntity] } },
                        { key: "show_dom_mkt_al", value: "Marketing Airlines", rLabel: "Show Marketing Airlines", checked: true, disableFor: { entity: [nonFinancialEntity, financialEntity] } },
                        { key: "show_dom_opt_al", value: "Operating Airlines", rLabel: "Show Operating Airlines", checked: true, disableFor: { entity: [nonFinancialEntity, financialEntity] } },
                        { key: "show_agencies", value: "Agency Name/Number", rLabel: "Show Agency Name/Number", checked: true, disableFor: { entity: [nonFinancialEntity, financialEntity] } },
                        { key: "show_agency_groups", value: "Agency Group Name", rLabel: "Show Agency Group Name", checked: true, disableFor: { entity: [nonFinancialEntity, financialEntity, agencyEntity] } },
                        { key: "show_airline_groups", value: "Airline Group Name", rLabel: "Show Airline Group Name", checked: true, disableFor: { entity: [nonFinancialEntity, financialEntity], subscription: aggt } },
                        { key: "show_source", value: "Data Source", rLabel: "Show Data Source", checked: true }
                    ]
                }]
            }, {
                name: "Data Source",
                showName: false,
                fields: [{
                    name: "source",
                    label: "Data Source",
                    type: "typeahead",
                    matchedOnly: true,
                    value: [],
                    labelKey: "description",
                    codeKey: "code",
                    hideKey: true,
                    required: true,
                    minChar: 0,
                    disabled: false,
                    show: true,
                    selectAll: true,
                    options: [
                        { code: "1", description: "ASP Settled Data", enableFor: { dataSource: [GLOBALDATA, "ASP", "ASP_BSP"] } },
                        { code: "2", description: "Contributed Direct", enableFor: { dataSource: [GLOBALDATA, "DIRECT"] } },
                        { code: "3", description: "BSP Settled Data", enableFor: { dataSource: [GLOBALDATA, "ASP_BSP", "BSP"] } },
                        { code: "4", description: "Contributed Indirect", enableFor: { dataSource: [GLOBALDATA, "INDIRECT"] } }
                    ],
                    placeholder: "Select Data Source(s)"
                }]
            }]
        }
    ],
    chartsLists: [
        {
            label: "Tab 1",
            wraps: [
                {
                    name: "top_performers",
                    label: "Top Performers",
                    showName: true,
                    fields: [{
                        name: "trips",
                        label: "Trips",
                        help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                        type: "XYChart",
                        orientation: "V",
                        sideBySide: true,
                        enableBars: true,
                        enableValues: true,
                        labelIndex: true,
                        labels: { x: "Airline", y: "Trip" },
                        serviceKeys: {
                            labels: ["dom_mkt_al_name", "market", "transaction"],
                            values: ["trip", "trip_share"]
                        },
                        keys: {
                            labels: ["dom_mkt_al_nm", "market", "transaction"],
                            values: ["trip"],
                            headers: {
                                dom_mkt_al_nm: "Dominant Marketing Airlines"
                            },
                            symbol: "",
                            percentSymbol: ".%",
                            sort: ""
                        }
                    }]
                }, {
                    name: "fares",
                    label: "",
                    showName: false,
                    fields: [{
                        name: "fares",
                        label: "Fares",
                        help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                        type: "XYChart",
                        orientation: "V",
                        sideBySide: true,
                        labelIndex: true,
                        enableBars: true,
                        enableValues: true,
                        labels: { x: "Airline", y: "Fare" },
                        serviceKeys: {
                            label: "dom_mkt_al_name",
                            values: ["blended_fare"]
                        },
                        keys: {
                            label: "dom_mkt_al_nm",
                            values: ["blended_fare"],
                            legends: ["Total Sales Amount"],
                            headers: {
                                dom_mkt_al_nm: "Dominant Marketing Airlines"
                            },
                            symbol: "$",
                            percentSymbol: ".%",
                            sort: ""
                        }
                    }]
                }, {
                    name: "revenue",
                    label: "",
                    showName: false,
                    fields: [{
                        name: "revenue",
                        label: "Revenue",
                        help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                        type: "DonutChart",
                        showTotal: true,
                        sideBySide: true,
                        totalText: "Blended Revenue",
                        serviceKeys: {
                            label: "dom_mkt_al_name",
                            values: ["blended_revenue", "revenue_share"]
                        },
                        keys: {
                            label: "dom_mkt_al_nm",
                            values: ["blended_revenue"],
                            headers: {
                                dom_mkt_al_nm: "Dominant Marketing Airlines"
                            },
                            symbol: "$",
                            percentSymbol: ".%",
                            sort: ""
                        }
                    }]
                }
            ]
        }
    ]
};
