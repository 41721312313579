import React, { ReactNode } from 'react';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import * as XLSX from 'xlsx';

const zlib = require("react-zlib-js");
const Buffer = require("buffer").Buffer;

interface toggelProps {
    children: ReactNode,
    eventKey: any
}

export const CustomToggle = ({ children, eventKey }: toggelProps) => {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
        console.log('totally custom!'),
    );

    return (
        <span onClick={decoratedOnClick}>
            {children}
        </span>
    );
};

export const toTree = (codeKey: string, labelKey: string, data: any) => {
    const r = data, map: any = {}, roots = [];
    let i;
    for (i = 0; r && i < r.length; i += 1) {
        r[i].label = r[i][labelKey] + (r[i][codeKey] && r[i][codeKey].length > 0 ? " (" + (r[i].top ? r[i][codeKey].split(",").join(", ") : r[i][codeKey].substring(0, r[i][codeKey].indexOf(":") >= 0 ? r[i][codeKey].indexOf(":") : r[i][codeKey].length)) + ")" : "");
        map[r[i][codeKey]] = i; // initialize the map
        r[i].children = []; // initialize the children
    }
    for (i = 0; r && i < r.length; i += 1) {
        const node = r[i];
        if (node.parent && node.parent[0] !== null && node.parent !== "" && map[node.parent] >= 0) {
            r[map[node.parent]].children.push(node);
        } else {
            roots.push(node);
        }
    }
    return [...roots];
}

export const refreshActivity = () => {
    localStorage.setItem("lastActive", new Date().toString());
}

export const clearLocalStorage = () => {
    Object.keys(localStorage).forEach((lKey: string) => {
        if (lKey.indexOf("REF:") < 0 && lKey !== "report_id") {
            localStorage.removeItem(lKey);
        }
    });
    if (localStorage.getItem("skip_report_id") !== "T") {
        localStorage.removeItem("report_id");
        localStorage.removeItem("skip_report_id")
    }
}

export const capitalize = (phrase: string | "") => {
    return phrase.replace(/\w\S*/g, (txt: any) => { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase() });
};


export const unzipJson = (data: any) => {
    return JSON.parse(zlib.inflateSync(new Buffer(data, 'base64')).toString());
}

export const processResponse = (response: any) => {
    if (response?.data?.hasOwnProperty("base64(zip(o))")) {
        return unzipJson(response.data["base64(zip(o))"]);
    } else {
        return response.data;
    }
}

export const convertFileBase64 = (file: any) => {
    return new Promise((reslove, reject) => {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(file)
        fileReader.onload = () => reslove(fileReader.result)
        fileReader.onerror = (e) => reject(e)
    })
};

export const findTreeItem: any = (field: any, options: any[], item: any) => {
    let itemsFound: any[] = [];
    for (let i = 0; i < options.length; i++) {
        const option = options[i];
        if (option.children) {
            itemsFound = [...itemsFound, ...findTreeItem(field, option.children, item)];
        } else {
            if (option[field.codeKey] === item[field.codeKey]) {
                itemsFound.push(option);
            }
        }
    };
    return itemsFound;
};

export const getTreeNodes: any = (field: any, options: any[]) => {
    let itemsFound: any[] = [];
    for (let i = 0; i < options.length; i++) {
        const option = options[i];
        if (option.children) {
            itemsFound = [...itemsFound, ...getTreeNodes(field, option.children)];
        } else {
            itemsFound.push(option);
        }
    };
    return itemsFound;
};

export const toExcel = (name: any, data: any) => {
    //const svg: any = chartWrap?.querySelector("svg");
    const tabName = name || 'DP-AgencyPerformance';
    const fileName = tabName + ".xlsx";

    var ws = XLSX.utils.json_to_sheet(data);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, tabName);
    XLSX.writeFile(wb, fileName);
}

export const getTreeChildren = (customer: any, fields: any) => {
    let pfiltered = fields.filter((option: any) => !option.showFor || (option.showFor.subscription && (customer?.data_content?.indexOf(option.showFor.subscription) >= 0)) || (option?.showFor.entity && (option?.showFor?.entity.indexOf(customer.entity) >= 0)))
    pfiltered.map((option: any, i: number) => {
        if (option.children && option.children.length > 0) {
            option.children.map((grandChild: any, j: number) => {
                if (grandChild.children) {
                    pfiltered[i].children[j].children = grandChild.children.filter((child: any) => !child.showFor || (child.showFor.entity && child.showFor.entity.indexOf(customer.entity) >= 0) || (child.showFor.subscription && customer?.data_content?.indexOf(child.showFor.subscription) >= 0))
                    pfiltered[i].children[j].key = pfiltered[i].children[j].children.map((key: any) => key.key).toString()
                    if (pfiltered[i].children[j].children && pfiltered[i].children[j].children.length === 1) delete pfiltered[i].children[j].children
                }
                return null
            })
            pfiltered[i].children = option.children.filter((child: any) =>
                (!child.showFor && !child.disableFor)
                || (child?.disableFor && customer.data_content.indexOf(child?.disableFor?.subscription) < 0)
                || (child?.showFor?.subscription && customer.data_content?.indexOf(child?.showFor?.subscription) >= 0)
                || (child?.showFor?.entity && (child?.showFor?.entity.indexOf(customer.entity) >= 0))
                || (child?.showFor?.dataSource && child?.showFor?.dataSource?.map((src: String) => customer.data_source.includes(src)).indexOf(true) >= 0))
            pfiltered[i].children.length > 1 ? pfiltered[i].key = pfiltered[i].children.map((key: any) => key.key).toString() : pfiltered[i].key = pfiltered[i].children.map((key: any) => key.key).toString() + ","
        }
        if (option.proprietary && option.proprietary.indexOf(customer.entity) >= 0) {
            option.children.forEach((child: any) => {
                const index = pfiltered.findIndex((opt: any) => opt.label === child.label) || -1
                if (child.parent) {
                    const pIndex = pfiltered.findIndex((opt: any) => opt.label === child.parent)
                    if (child.grandParent) {
                        const gIndex = pfiltered[pIndex].children.findIndex((opt: any) => opt.label === child.grandParent)
                        if (pfiltered[pIndex].children[gIndex]?.children) {
                            pfiltered[pIndex].children[gIndex].children = pfiltered[pIndex].children[gIndex].children.filter((item: any) => item.key !== child.key)
                        }
                    } else {
                        pfiltered[pIndex].children = pfiltered[pIndex].children.filter((item: any) => item.key !== child.key)
                    }
                } else {
                    if (index >= 0) pfiltered.splice(index, 1)
                }
            })
        }
        // only if no proprietary fields
        option.label === "Proprietary Fields" && option.children.length <= 0 && pfiltered.splice(i, 1)
        return null
    })
    return pfiltered
};

export const random = () => {
    // Generates & returns numbers between 0 and 1
    // Alternate for Math.random() - Insecure
    return parseFloat("0." + Math.abs(window.crypto.getRandomValues(new Int8Array(1))[0]));
}

export const setTheme = (name: string) => {
    document.body.classList.add(name);
}

export const clearTheme = (name: string) => {
    document.body.classList.remove(name);
}

export const toggleTheme = (name: string) => {
    document.body.classList.toggle(name);
}

export const setHideJumbotron = () => {
    document.querySelector(".fds-pl-jumbotron")?.classList.add("hide");
    document.querySelector(".fds-pl-container.first")?.classList.add("firstBKP")
    document.querySelector(".fds-pl-container.first")?.classList.remove("first");
}

export const clearHideJumbotron = () => {
    document.querySelector(".fds-pl-jumbotron")?.classList.remove("hide");
    document.querySelector(".fds-pl-container.firstBKP")?.classList.add("first");
}

export const setHideTopHeader = () => {
    document.querySelector(".topHeader")?.classList.add("hide");
}

export const clearHideTopHeader = () => {
    document.querySelector(".topHeader")?.classList.remove("hide");
}