import React, { useState, useEffect, useLayoutEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Container, Row, Col, Button, Form, Card, Spinner, Alert, OverlayTrigger, Tooltip, Table } from 'react-bootstrap';
import DropdownTreeSelect from 'react-dropdown-tree-select'
import 'react-dropdown-tree-select/dist/styles.css';
import * as FDS from '@arctravel/react-fds/lib';
import { useSelector, useDispatch } from 'react-redux';
//import { useExcelDownloder } from 'react-xls';
import { toTree, refreshActivity, toExcel } from '../utils';
import './referenceData.scss';
import * as XLSX from 'xlsx';
import Feedback from '../modals/Feedback';

declare const window: any;

type AirlineProps = {
    code: string, name: string, ['global region']: string, ['country name']: string, ['country code']: string,
    parent: string, ['trade name']: string, ['location type']: string, ['street address']: string, city: string, state: string, ['postal code']: string,
    country: string, ['phone number']: string, type: string, description: string, acct: string, ['service type']: string
}
const fontWidth = 16;
export const ReferenceData = () => {
    //const { ExcelDownloder, Type } = useExcelDownloder();
    const params: any = useParams();
    const historyNav = useHistory();
    const dispatch = useDispatch();
    const referenceDataRef = useSelector((state: any) => state.referenceDataRef);
    const lookupBucketRef = useSelector((state: any) => state.lookupBucketRef);
    const [references, setReferences]: any = useState({});
    const referenceLoadedRef = useSelector((state: any) => state.referenceLoadedRef);
    const [TableData, setTableData] = useState<AirlineProps[]>([]);

    const [display_message, setDisplay_message] = useState<string>('');

    const [agencyIp, setAgencyIp]: any = useState({});
    const [agencyLoading, setAgencyLoading] = useState<boolean>(false);
    const [bucketIp, setBucketIp] = useState([]);
    const [showBucket, setShowBucket] = useState(false);

    const [editableGroup, setEditableGroup]: any = useState({ acct: "", code: "", description: "", name: "", values: [], regn_values: [] });
    const [type, setType] = useState(window.ENV.references[params.id]);
    const types: any = {
        "airport": "Airport Lookup",
        "airline": "Airline Lookup",
        "region": "Region Lookup",
        "agency": "Travel Agency Lookup",
        "dtperiods": "Date Periods Loaded"
    }

    const addNewGroup = useCallback(() => {
        setTableData([])
        setDisplay_message('');
        // For date periods no additional filter available. so load the data to table
        if (type === window.ENV.references.dtperiods) {
            setEditableGroup({});
            setTableData([...(referenceDataRef[window.ENV.references.dtperiods] || [])]);
        } else {
            setEditableGroup({ acct: "", code: "", description: "", name: "", values: [], regn_values: [] })
            //setTableData([])
        }
    }, [referenceDataRef, type]);

    useEffect(() => {
        dispatch({ type: "REPORT_IP", data: {} });
        if (agencyLoading) {
            getAgency();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [agencyLoading]);

    useEffect(() => {
        dispatch({ type: "UNLOCK_BUCKET" })
        setType(window.ENV.references[params.id]);
    }, [params.id, dispatch])

    useLayoutEffect(() => {
        // Updates on agencyIp change
    }, [agencyIp])

    useEffect(() => {
        setBucketIp(lookupBucketRef);
    }, [lookupBucketRef])

    useEffect(() => {
        refreshActivity();
        if (referenceLoadedRef.status) {
            const fields = [{
                labelKey: "description",
                codeKey: "code",
                url: window.ENV.references.agency,
            }];

            fields.forEach((field: any) => {
                setReferences((prev: any) => {
                    prev[field.url] = referenceDataRef[field.url] && field.type === "tree" ? toTree(field.codeKey, field.labelKey, referenceDataRef[field.url]) : referenceDataRef[field.url];
                    return prev;
                });
            });
        }

        const fields = [{
            labelKey: "description",
            codeKey: "code",
            url: window.ENV.references.airline,
        }, {
            labelKey: "description",
            codeKey: "code",
            url: window.ENV.references.airport,
        }, {
            type: "tree",
            labelKey: "name",
            codeKey: "code",
            url: window.ENV.references.region,
        }, {
            type: "tree",
            labelKey: "name",
            codeKey: "code",
            url: window.ENV.references.agency_region,
        }, {
            labelKey: "description",
            codeKey: "code",
            url: window.ENV.references.city,
        }, {
            labelKey: "DATE_TYPE",
            codeKey: "START_DATE",
            unique: true,
            url: window.ENV.references.dtperiods,
        }];

        fields.forEach((field: any) => {
            setReferences((prev: any) => {
                prev[field.url] = referenceDataRef[field.url] && field.type === "tree" ? toTree(field.codeKey, field.labelKey, referenceDataRef[field.url]) : referenceDataRef[field.url];
                return prev;
            });
        });

        addNewGroup()
    }, [referenceDataRef, referenceLoadedRef, addNewGroup]);

    const changeType = (ev: any) => {
        setType(ev.target.value);

        Object.keys(types).forEach((tpe: any) => {
            if (ev.target.value.indexOf(tpe) > 0) {
                historyNav.push("/ref/" + tpe);
            }
        })
        // Clear Table Data
        setTableData([]);
    }

    const groupItem = (data: any) => {
        refreshActivity();
        setAgencyIp((prev: any) => { return {} });
        if (data.length <= 0) {
            return;
        }
        setEditableGroup((prev: any) => {
            const p = [];
            const temp = [];
            for (let k = 0; k < data.length; k++) {
                const val = data[k].code;
                if (val && val.length > 0 && p.indexOf(val) < 0) {
                    p.push(val);
                    temp.push(data[k]);
                }
            }

            setTableData(temp);
            return { ...prev, values: [...p], regn_values: [] };
        })
    }

    let treeVals: any = [];

    const onTreeChange = (currentNode: any, selectedNodes: any) => {
        treeVals = [...selectedNodes];
    }

    const onBlur = () => {
        refreshActivity();
        setEditableGroup((prev: any) => {
            const p: any = [];
            for (let k = 0; k < treeVals.length; k++) {
                const code = treeVals[k].name + (treeVals[k]['region_type'] ? ":" + treeVals[k]['region_type'] : '') + ":" + treeVals[k].code
                if (p.indexOf(code) < 0) {
                    p.push(code);
                }
            }
            // populate data
            // IATA_SUBZNE1_CD :   Global region==selected ----// COUNTRY_CD : country name == selected
            // STATE_CD : name==selected ----// IATA_ZNE_CD: selected code==parent==> selected names==global region
            let table_data = get_region_data(p);
            setTableData(table_data);
            return { ...prev, values: [...p] };
        })

    }

    const get_region_data = (p: any) => {
        let refTemp: any = referenceDataRef[window.ENV.references.region];
        let table_data: any = []
        for (let k = 0; k < p.length; k++) {
            let name = p[k].split(':')[0]
            let zone_cd = p[k].split(':')[1]
            let cd = p[k].split(':')[2]
            if (zone_cd === 'IATA_SUBZNE1_CD') {
                let ref_zone1 = refTemp.filter((item: any) => (!item.top && item['global region'] && item['global region'] === name)).map(function (item: any) {
                    return { "country name": item['country name'], "country code": item['country code'], "state name": item['region_type'] === 'STATE_CD' ? item['name'] : '', "state": item['region_type'] === 'STATE_CD' ? item['code'].split(":")[0] : '', "global region": item['global region'] };
                })
                table_data = [...ref_zone1, ...table_data]
            } else if (zone_cd === 'COUNTRY_CD') {
                let ref_zone1 = refTemp.filter((item: any) => (!item.top && item['country name'] && item['country code'] === cd)).map(function (item: any) {
                    return { "country name": item['country name'], "country code": item['country code'], "state name": item['region_type'] === 'STATE_CD' ? item['name'] : '', "state": item['region_type'] === 'STATE_CD' ? item['code'].split(":")[0] : '', "global region": item['global region'] };
                })
                table_data = [...ref_zone1, ...table_data]
            } else if (zone_cd === 'STATE_CD') {
                let ref_zone1 = refTemp.filter((item: any) => (!item.top && item.name && item.name === name)).map(function (item: any) {
                    return { "country name": item['country name'], "country code": item['country code'], "state name": item['region_type'] === 'STATE_CD' ? item['name'] : '', "state": item['region_type'] === 'STATE_CD' ? item['code'].split(":")[0] : '', "global region": item['global region'] };
                })
                table_data = [...ref_zone1, ...table_data]
            } else if (zone_cd === 'IATA_ZNE_CD') {
                let global_regions = refTemp.filter((item: any) => (!item.top && item.code && item.parent === cd + ":" + zone_cd)).map(function (item: any) {
                    return item.name;
                })
                let ref_zone1 = refTemp.filter((item: any) => (!item.top && item['global region'] && global_regions.includes(item['global region']))).map(function (item: any) {
                    return { "country name": item['country name'], "country code": item['country code'], "state name": item['region_type'] === 'STATE_CD' ? item['name'] : '', "state": item['region_type'] === 'STATE_CD' ? item['code'].split(":")[0] : '', "global region": item['global region'] };
                })
                table_data = [...ref_zone1, ...table_data]
            }
        }
        return table_data;
    }

    // Get Region filter data for Agency
    const onBlur_agency = () => {
        setEditableGroup((prev: any) => {
            for (let k = 0; k < treeVals.length; k++) {
                let name_code: any = treeVals[k]['region_type'] && treeVals[k]['region_type'] === "COUNTRY_CD" ? treeVals[k].code : treeVals[k].name;
                name_code = treeVals[k]['region_type'] ? name_code + ":" + treeVals[k]['region_type'] + ":" + treeVals[k].code : name_code;
                if (prev.regn_values.indexOf(name_code) < 0) {
                    prev.regn_values.push(name_code);
                }
            }
            // for agency search we need region data
            setAgencyIp((prevA: any) => {
                let global_region_data: any = get_global_regn(treeVals);
                Object.keys(global_region_data).forEach((gKey: any) => {
                    if (global_region_data[gKey] && global_region_data[gKey].length > 0) {
                        if (prevA[gKey]) {
                            if (prevA[gKey].indexOf(global_region_data[gKey]) < 0) {
                                prevA[gKey] = [...global_region_data[gKey]];
                            }
                        } else {
                            prevA[gKey] = [...global_region_data[gKey]];
                        }
                    }
                })
                return { ...prevA }
            })
            return { ...prev };
        })
    }

    // Agency Data has only IATA_SUBZNE1 info. so if
    // IATA_ZNE_CD is selected(like Asia)- get subzones under it
    // If Country or State selected, use it as filter instead of Global Region
    const get_global_regn = (tree_data: any) => {
        let refTemp: any = referenceDataRef[window.ENV.references.region];
        // check if it is region type - IATA_ZNE_CD/IATA_SUBZNE1_CD/COUNTRY_CD/STATE_CD
        // global region = IATA_SUBZNE1_CD/
        let response: any = { 'global region': [], 'country': [], 'state': [] }
        for (let k = 0; k < tree_data.length; k++) {
            if (["REGION_CD", "IATA_SUBZNE1_CD"].indexOf(tree_data[k].region_type) >= 0 && response['global region'].indexOf(tree_data[k].name) < 0) {
                response['global region'].push(tree_data[k].name);
            } else if (tree_data[k].region_type === "IATA_ZNE_CD") {
                let subzone_cds: any = refTemp.filter((item: any) => (item['parent']) === tree_data[k].code).map(function (item: any) {
                    return item.name
                })
                if (subzone_cds) { subzone_cds.forEach((item: any) => response['global region'].push(item)) }
            } else if (tree_data[k].region_type === "COUNTRY_CD") {
                response['country'].push(tree_data[k].code);
            } else if (tree_data[k].region_type === "STATE_CD") {
                response['state'].push(tree_data[k].code);
            }
        }
        return response;
    }
    const removeItem = (value: string) => {
        setEditableGroup((prev: any) => {
            prev.values = prev.values.filter((item: string) => item !== value);
            return { ...prev };
        })
        setTableData(TableData.filter((x) => x.code !== value));

    }

    const removeItem_R = (value: string) => {
        let table_data: any;
        setEditableGroup((prev: any) => {
            prev.values = prev.values.filter((item: string) => item !== value);
            table_data = get_region_data(prev.values);
            setTableData(table_data);
            return { ...prev };
        })
    }
    const removeItem_region = (value: string) => {
        let region_type = value.split(':')[1]
        let refTemp: any, subzone_cds: any;
        setEditableGroup((prev: any) => {
            prev.regn_values = prev.regn_values.filter((item: string) => item !== value);
            setAgencyIp((prevA: any) => {
                if (region_type === "IATA_ZNE_CD") {
                    refTemp = referenceDataRef[window.ENV.references.region];
                    subzone_cds = refTemp.filter((item: any) => (item['parent']) === value.split(':')[2]).map(function (item: any) {
                        return item.name
                    })
                }
                switch (region_type) {
                    case 'STATE_CD':
                        prevA["state"] = prevA.state.filter((item: string) => item !== value.split(':')[0]);
                        break;
                    case 'COUNTRY_CD':
                        prevA["country"] = prevA.country.filter((item: string) => item !== value.split(':')[0]);
                        break;
                    case 'IATA_SUBZNE1_CD':
                        prevA["global region"] = prevA['global region']?.filter((item: string) => item !== value.split(':')[0]);
                        break;
                    case 'IATA_ZNE_CD':
                        prevA["global region"] = subzone_cds ? subzone_cds.forEach((code: any) => prevA['global region']?.filter((item: string) => item !== code)) : prevA['global region'];
                        break;
                }
                return { ...prevA }
            })
            return { ...prev };
        })
        // refresh table data
        // getAgency();
    }

    const getDownloadFileName = (() => {
        return types[type.substring(type.lastIndexOf("entity=") + 7, type.indexOf("&pageIndex"))]
    });

    /*const getDownloadData = (() => {
        let exportTableObject = adjustNameExport(TableData);
        let export_data = { "Data": exportTableObject }
        return export_data;
    });*/

    const getAgency = () => {
        let refTemp: any = [...references[window.ENV.references.agency]].filter((d: any) => !d.top);
        Object.keys(agencyIp).forEach((aKey: any) => {
            if (agencyIp[aKey]) {
                if (agencyIp[aKey].length > 0) {
                    switch (aKey) {
                        case 'global region':
                        case 'country':
                        case 'state':
                            refTemp = refTemp.filter((item: any) => { return item[aKey] && agencyIp[aKey].includes(item[aKey]) });
                            break;
                        case 'name':
                            refTemp = refTemp.filter((item: any) => { return item[aKey] && item[aKey].toUpperCase().includes(agencyIp[aKey].toUpperCase()) })
                            break;
                        case 'postal code':
                        case 'city':
                            refTemp = refTemp.filter((item: any) => { return item[aKey] && item[aKey].toUpperCase().indexOf(agencyIp[aKey].toUpperCase()) === 0 })
                            break;
                        default:
                            refTemp = refTemp.filter((item: any) => (item[aKey]) === agencyIp[aKey]);
                    }
                }
            }
        })
        if (refTemp) {
            // Download Data if more than 10k records in search
            if (refTemp.length < 10000) {
                setTableData(refTemp);
            } else {
                // Clear Table Data
                setTableData([]);
                downloadFile(refTemp)
            }
        } else {
            setDisplay_message('No Records')
        }

        setAgencyLoading(false);
    }

    // Download Data if more than 10k records in search
    const downloadFile = async (myData: any) => {
        const fileName = getDownloadFileName() + ".xlsx";
        let data = adjustNameExport(myData);
        var ws = XLSX.utils.json_to_sheet(data);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Data");
        XLSX.writeFile(wb, fileName);

        setDisplay_message('Large set of records, data xlsx File will download');
    }

    const Capitalize = (textIp: any) => {
        var splitStr = textIp.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            // You do not need to check if i is larger than splitStr length, as your for does that for you
            // Assign it back to the array
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        // Directly return the joined string
        return splitStr.join(' ');
    }

    // Column names adjust for display purpose
    const adjustName = (col_name: any) => {
        if (type === window.ENV.references.agency) {
            col_name = col_name === 'code' ? 'Agency Number' : col_name === 'parent' ? 'Agency Home Office' : col_name
        }
        if (type === window.ENV.references.airline) {
            col_name = col_name === 'code' ? 'Airline' : col_name === 'acct' ? 'Airline Billing Code' : col_name
        }
        return col_name;
    }

    const adjustNameExport = (data: any) => {
        let exportTableData: any = data;
        if (type === window.ENV.references.agency) {
            exportTableData = data.map((item: any) => {
                return {
                    "Agency Number": item.code,
                    "Agency Home Office": item['parent'],
                    "Name": item.name,
                    "Trade Name": item["trade name"],
                    "Location Type": item["location type"],
                    "Street Address": item["street address"],
                    "City": item["city"],
                    "State": item["state"],
                    "Postal Code": item["postal code"],
                    "Country": item["country"],
                    "Global Region": item["global region"],
                    "Phone Number": item["phone number"],
                    "Type": item["type"],
                    "Description": item["description"]
                }
            })
        }
        if (type === window.ENV.references.airline) {
            exportTableData = data.map((item: any) => {
                return {
                    "Airline Billing Code": item.acct,
                    "Airline": item.code,
                    "Name": item.name,
                    "Service Type": item["service type"],
                    "Country": item.country,
                    "Country Name": item["country name"],
                    "Description": item.description
                }
            })
        }
        return exportTableData;
    }

    return (
        <React.Fragment>
            <Feedback page="Reference Data" />
            <FDS.FDSLevel type="two" name="referencePage" mainTitle="Reference Data"
                subTitle="Search for airline(s), airport(s), region(s), agency(ies), view date periods">
                <Container className="fds-pl-container first float">
                    {
                        bucketIp.length > 0 && type === window.ENV.references.agency ?
                            <div className='bucketLogo pe-3' title="Agency Bucket">
                                <div className='logo'>
                                    <i className='fds-glyphs-briefcase' onClick={() => { setShowBucket(true) }}></i> {bucketIp.length}
                                </div>
                                {showBucket ? <div className='bucketWrap me-3'>
                                    <Card className='p-3'>
                                        <Row>
                                            <Col>
                                                <h3><i className='fds-glyphs-briefcase pe-3'></i>Agency Bucket</h3>
                                            </Col>
                                            <Col style={{ textAlign: 'right' }}>
                                                <a href="closelink" onClick={(ev) => { ev.preventDefault(); setShowBucket(false) }}>Close</a>
                                            </Col>
                                        </Row>

                                        <div className='pb-3 bucketList'>
                                            {bucketIp.map((item: any, i: number) => {
                                                return (<div className='fds-tag m-0 mb-1' key={i}>
                                                    {item.name}&nbsp;
                                                    ({item?.code})
                                                    <i className="fds-glyphs-clear" onClick={() => { dispatch({ type: "REMOVE_BUCKET_ITEM", data: item }) }}></i>
                                                </div>)
                                            })}
                                        </div>

                                        <Row className='pt-3' style={{ textAlign: 'center' }}>
                                            <Col>
                                                <Button variant='secondary' onClick={() => { dispatch({ type: "CLEAR_BUCKET" }) }}>Clear All</Button>
                                            </Col>
                                            <Col>
                                                <Button variant='primary' onClick={() => { dispatch({ type: "LOCK_BUCKET" }); historyNav.push("/groupings/agency") }}>Start Group</Button>
                                            </Col>
                                        </Row>
                                    </Card>
                                </div> : null}
                            </div> : null
                    }
                    <Row className="fds-pl-column p-0 shadow">
                        <Col className="leftWrap">
                            <div className="minimize">
                                <Button variant="primary" size="sm" onClick={(ev) => {
                                    ev?.currentTarget?.parentElement?.parentElement?.classList?.toggle('min')
                                }}>
                                    <i className="fds-glyphs-arrow-left3"></i>
                                </Button>
                            </div>
                            <div className="blankable pb-3 mb-3">
                                <div className="card p-3">
                                    <Form.Group className="form-group">
                                        <h3>Reference Data</h3>
                                        <div className="select">
                                            <Form.Select aria-label='select reference lookup' data-testid="select-data-type" value={type} onChange={(ev) => { setEditableGroup({}); setAgencyIp({}); changeType(ev); }}>
                                                <option value={window.ENV.references.airline}>{types['airline']}</option>
                                                <option value={window.ENV.references.airport}>{types['airport']}</option>
                                                <option value={window.ENV.references.region}>{types['region']}</option>
                                                <option value={window.ENV.references.agency}>{types['agency']}</option>
                                                <option value={window.ENV.references.dtperiods}>{types['dtperiods']}</option>
                                            </Form.Select>
                                        </div>
                                    </Form.Group>
                                </div>

                                {referenceLoadedRef.status ?
                                    <div>
                                        <hr />
                                        {type === window.ENV.references.dtperiods ? null :
                                            <Form.Group controlId="values">
                                                <Form.Label>Search (separated by a comma)</Form.Label>
                                                {type === window.ENV.references.region ?
                                                    <div data-testid="select-data-region">
                                                        <DropdownTreeSelect
                                                            data={references[type] ? references[type].filter((ref: any) => !ref.top) : []}
                                                            keepTreeOnSearch={true}
                                                            onFocus={() => console.log("FOCUS")}
                                                            onBlur={() => onBlur()}
                                                            onChange={(currentNode, selectedNodes) => onTreeChange(currentNode, selectedNodes)} /> </div> :
                                                    <div data-testid="select-data">
                                                        <FDS.FDSTypeahead
                                                            dataList={references[type] ? references[type].filter((ref: any) => !ref.top) : []}
                                                            minChar={window.ENV.references.airport === type ? 2 : window.ENV.references.agency === type ? 3 : 0}
                                                            clearOnSelect={true}
                                                            matchedOnly={true}
                                                            labelKey="description"
                                                            codeKey="code"
                                                            truncateCodes={true}
                                                            onChange={(data) => groupItem(data)} /></div>

                                                }
                                                {editableGroup?.values?.length > 0 ? <div className="ps-3 pe-3 pt-2 pb-2 mt-1" style={{ border: "1px solid #ccc", borderRadius: "4px", maxHeight: '200px', overflow: 'auto', background: 'white' }}>
                                                    {editableGroup?.values?.map((value: any, j: number) => {
                                                        return (
                                                            <div className="fds-tag primary mt-1 mb-1" key={j} data-testid="selected-data">
                                                                {value.indexOf(":") > 0 ? value.substring(0, value.indexOf(":")) : value}
                                                                {<i className="fds-glyphs-clear" onClick={() => type === window.ENV.references.region ? removeItem_R(value) : removeItem(value)}></i>}
                                                            </div>
                                                        )
                                                    })}
                                                </div> : null}
                                            </Form.Group>
                                        }
                                        {type === window.ENV.references.agency ? <div className="pt-3">
                                            <hr />
                                            <div className="orWrap">OR</div>
                                            <div className="card p-3 mt-3">
                                                <Form.Group controlId="parent">
                                                    <Form.Label>Travel Agency Home Office</Form.Label>
                                                    <Form.Control type="text" data-testid="agency-hol-testid" value={agencyIp['parent'] || ''} autoComplete="off" required={true}
                                                        onKeyUp={event => { if (event.key === "Enter") { getAgency(); } }}
                                                        onChange={(ev) => setAgencyIp((prev: any) => {
                                                            prev[ev.target.id] = ev.target.value;
                                                            return { ...prev }
                                                        })} />
                                                </Form.Group>
                                                <Form.Group controlId="name" className='mt-3'>
                                                    <Form.Label>Portion of Name</Form.Label>
                                                    <Form.Control type="text" data-testid="agency-name-testid" value={agencyIp['name'] || ''} autoComplete="off" required={true}
                                                        onKeyUp={event => { if (event.key === "Enter") { getAgency(); } }}
                                                        onChange={(ev) => setAgencyIp((prev: any) => {
                                                            prev[ev.target.id] = Capitalize(ev.target.value);
                                                            return { ...prev }
                                                        })} />
                                                </Form.Group>
                                                <Form.Group controlId="city" className='mt-3'>
                                                    <Form.Label>City Name</Form.Label>
                                                    <Form.Control type="text" data-testid="agency-city-testid" value={agencyIp['city'] || ''} autoComplete="off" required={true}
                                                        onKeyUp={event => { if (event.key === "Enter") { getAgency(); } }}
                                                        onChange={(ev) => setAgencyIp((prev: any) => {
                                                            prev[ev.target.id] = Capitalize(ev.target.value);
                                                            return { ...prev }
                                                        })} />
                                                </Form.Group>
                                                <Form.Group controlId="postal code" className='mt-3'>
                                                    <Form.Label>Postal Code</Form.Label>
                                                    <Form.Control type="text" data-testid="agency-pc-testid" value={agencyIp['postal code'] || ''} autoComplete="off" required={true}
                                                        onKeyUp={event => { if (event.key === "Enter") { getAgency(); } }}
                                                        onChange={(ev) => setAgencyIp((prev: any) => {
                                                            prev[ev.target.id] = ev.target.value;
                                                            return { ...prev }
                                                        })} />
                                                </Form.Group>
                                                <Form.Group controlId="state" className='mt-3'>
                                                    <Form.Label>State Code &nbsp;
                                                        <OverlayTrigger
                                                            placement="auto"
                                                            overlay={<Tooltip>State Code should be a 2 character code.</Tooltip>}
                                                        >
                                                            <i className="fds-glyphs-info infoTxt" />
                                                        </OverlayTrigger>
                                                    </Form.Label>
                                                    <Form.Control type="text" data-testid="agency-state-testid" value={agencyIp['state'] || ''} autoComplete="off" required={true} maxLength={2}
                                                        onKeyUp={event => { if (event.key === "Enter") { getAgency(); } }}
                                                        onChange={(ev) => setAgencyIp((prev: any) => {
                                                            prev[ev.target.id] = ev.target.value.toUpperCase();
                                                            return { ...prev }
                                                        })} />
                                                </Form.Group>
                                                <Form.Group controlId="region" className='mt-3'>
                                                    <Form.Label style={{ textTransform: 'capitalize' }}>Region</Form.Label>
                                                    <div data-testid="select-agency-region">
                                                        <DropdownTreeSelect
                                                            data={references[window.ENV.references.agency_region] ? references[window.ENV.references.agency_region].filter((ref: any) => !ref.top) : []}
                                                            keepTreeOnSearch={true}
                                                            onFocus={() => console.log("FOCUS")}
                                                            onBlur={() => onBlur_agency()}
                                                            onChange={(currentNode, selectedNodes) => { onTreeChange(currentNode, selectedNodes); }}
                                                        />
                                                    </div>
                                                    {editableGroup?.regn_values?.length > 0 ? <div className="ps-3 pe-3 pt-2 pb-2 mt-1" style={{ border: "1px solid #ccc", borderRadius: "4px", maxHeight: '200px', overflow: 'auto' }}>
                                                        {editableGroup?.regn_values?.map((regn_values: any, j: number) => {
                                                            return (
                                                                <div className="fds-tag primary mt-1 mb-1" key={j} data-testid="selected-data-region">
                                                                    {regn_values.split(':', 1)[0]}
                                                                    <i className="fds-glyphs-clear" onClick={() => removeItem_region(regn_values)}></i>
                                                                </div>
                                                            )
                                                        })}
                                                    </div> : null}
                                                </Form.Group>
                                                <div style={{ textAlign: 'right' }}>
                                                    <Button className="mt-3" variant="primary"
                                                        disabled={((agencyIp.parent || '') + (agencyIp.name || '') + (agencyIp.city || '') + (agencyIp['postal code'] || "") + (agencyIp.state || '')).length <= 0 && (editableGroup?.regn_values || []).length <= 0}
                                                        onClick={() => { setEditableGroup((prev: any) => { return { ...prev, values: [] } }); setAgencyLoading(true); setDisplay_message('') }}>Lookup</Button>
                                                </div>
                                            </div>
                                        </div> : null}
                                    </div> :
                                    <div className="p-3">
                                        <Spinner animation="border" role="status" variant="primary" size="sm">
                                            <span className="sr-only"></span>
                                        </Spinner> &nbsp;
                                        Loading Reference Data...
                                    </div>
                                }
                            </div>
                        </Col>
                        <Col className="pt-3" style={{ width: "0" }}>
                            <Row>
                                <Col>
                                    {agencyLoading ?
                                        <div className="p-3">
                                            <Spinner animation="border" role="status" variant="primary" size="sm">
                                                <span className="sr-only"></span>
                                            </Spinner> &nbsp;
                                            Loading Agency Data...
                                        </div> :
                                        <div data-testid="ref-datas">
                                            {TableData.length > 0 ?
                                                <div>
                                                    <Card id="cardWrap">
                                                        <Row>
                                                            <Col>
                                                                <div className="ps-3">
                                                                    <h3 className="mt-3">{types[type.substring(type.lastIndexOf("entity=") + 7, type.indexOf("&pageIndex"))]}</h3>
                                                                    <div className='type-body-small-on-light'>{editableGroup.values?.map((v: any, vi: number) =>
                                                                        <div key={vi}>
                                                                            {v?.split(":")[0] || v}
                                                                            {v?.split(":").length === 4 ?
                                                                                <span>({v?.split(":")[2] || null}: {v?.split(":")[3] || null})</span> :
                                                                                null
                                                                            }
                                                                        </div>
                                                                    )}</div>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div style={{ textAlign: 'right' }} className="exportIcons me-3 mb-2" data-testid="download_link">
                                                                    <Button variant='link' onClick={() => toExcel(getDownloadFileName(), TableData)}><i className="fds-glyphs-download"></i> Download</Button>
                                                                    {/*<ExcelDownloder
                                                                        style={{ color: 'blue', cursor: 'pointer' }}
                                                                        data={getDownloadData()}
                                                                        filename={getDownloadFileName()}
                                                                        type={Type.Link}
                                                                    >
                                                                        Download
                                                                        </ExcelDownloder>*/}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <div style={{ overflow: "auto" }}>
                                                            <div className="fds-table-wrap mt-3">
                                                                <Table className="fds-table table-striped" style={{ border: 'none' }}>
                                                                    <thead>
                                                                        <tr>
                                                                            {
                                                                                Object.keys(TableData[0]).map((col, i) => {
                                                                                    return (<th key={i} style={{ width: `${col.replace(/\s/gi, "").length * fontWidth + (i === 0 && type.indexOf('agency') > 0 ? 120 : 20) - (col.length * 3)}px`, textAlign: 'left', display: (i === 0 && type.indexOf('agency') > 0 ? "inline-block" : "") }}>
                                                                                        {i === 0 && type === window.ENV.references.agency ? <i className='addIcon fds-glyphs-folder-plus4 me-2' style={{ float: "left", margin: 0, cursor: "pointer" }} title="Add all to Bucket" onClick={() => { setShowBucket(true); dispatch({ type: "ADD_ALL_TO_BUCKET", data: TableData }) }}></i> : null}
                                                                                        {adjustName(col)}
                                                                                    </th>);
                                                                                })
                                                                            }
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {/* {groups} */}
                                                                        {TableData.map((d: any, i: any) => {
                                                                            return (
                                                                                <tr id={"R" + i} key={i}>
                                                                                    {
                                                                                        Object.keys(d).map((k, j) => <td key={j} style={{ width: `${k.replace(/\s/gi, "").length * fontWidth + (i === 0 && type.indexOf('agency') > 0 ? 120 : 20) - (k.length * 3)}px`, textAlign: 'left' }}>
                                                                                            {j === 0 && type === window.ENV.references.agency ? <i className='addIcon fds-glyphs-folder-plus4 me-2' style={{ cursor: "pointer" }} title="Add to Bucket" onClick={() => { setShowBucket(true); dispatch({ type: "ADD_TO_BUCKET", data: d }) }}></i> : null}
                                                                                            {d[k]}
                                                                                        </td>)
                                                                                    }
                                                                                </tr>
                                                                            );
                                                                        })}
                                                                    </tbody>
                                                                </Table>
                                                                <div className="pt-3 pb-3"></div>
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </div> :
                                                <div>
                                                    {display_message ?
                                                        <Alert className="m-3" variant="info">
                                                            <div className="icon"><i className="fds-glyphs-info2"></i></div>
                                                            <div className="body">{display_message}</div>
                                                        </Alert> : null
                                                    }
                                                </div>
                                            }
                                        </div>
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
                <div></div>
            </FDS.FDSLevel>
        </React.Fragment >
    )
}
