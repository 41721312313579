import React, { useState, useEffect } from 'react'
import { Pagination } from 'react-bootstrap';
import * as FDS from '@arctravel/react-fds';

export const DynamicPagination = (props: any) => {
    const { itemPerPage, totalItems, onPageChange } = props;
    const pageNumber = [];
    const [currentPage, setCurrentPage] = useState(props.currentPage || 1);
    for (let i = 1; i <= Math.ceil(totalItems / itemPerPage); i++) {
        pageNumber.push(i)
    }
    useEffect(() => {
        setCurrentPage(props.currentPage);
    }, [props.currentPage]);
    return (
        <Pagination className={props.className || ""}>
            <Pagination.Item
                onClick={() => {
                    setCurrentPage((prev: number) => {
                        const newPage = prev > 1 ? prev - 1 : prev;
                        onPageChange(newPage)
                        return newPage;
                    });

                }}>
                <i className='fds-glyphs-arrow-left3' />
            </Pagination.Item>

            {pageNumber.map((number: number) => {
                return (Math.abs(number - currentPage) < 3 || (currentPage <= 3 && number < 6) || ((currentPage > pageNumber.length - 3) && number >= pageNumber.length - 4)) &&
                    <Pagination.Item className={currentPage === number ? "active" : ""} key={number} onClick={() => {
                        setCurrentPage(number);
                        onPageChange(number);
                    }}>{FDS.numberFormatTxt(number)}</Pagination.Item>
            })}

            <Pagination.Item
                onClick={() => {
                    setCurrentPage((prev: number) => {
                        const newPage = prev < pageNumber.length ? prev + 1 : prev;
                        onPageChange(newPage)
                        return newPage;
                    });
                }}>
                <i className='fds-glyphs-arrow-right3' />
            </Pagination.Item>

            <div className='page-jump d-flex align-items-center ps-1'>
                {
                    <input type="number" aria-label='Page Jump Number for Table' onKeyDown={(ev: any) => {
                        if (ev.keyCode === 13) {
                            const page = parseInt(ev.target.value);
                            if (page > 0 && page <= pageNumber.length) {
                                setCurrentPage(page);
                                onPageChange(page);
                                ev.target.value = "";
                            }
                        }
                    }} />
                }
                of {FDS.numberFormatTxt(pageNumber.length)}
            </div>
        </Pagination>
    )
}