declare const window: any;

const thisYear = new Date().getFullYear();

export const CarrierDashboardTemplate: any = {
    reportName: "CARRIER_DASHBOARD",
    onLoadRun: true,                // Runs report on initial report open
    menu: "top",                    // Align filters on top instead of on left side
    darkBackground: true,           // For dark report background
    hideAllTopFilterOptions: true,  // Hides all buttons, saved template, clear all buttons from filters section that are on top
    onChangeSubmit: true,           // On change of filter value, report runs
    info: "The following provides processing statistics, by calendar month, for the last three years plus the current year-to-date. If you have questions, please contact your account manager.",
    lists: [{
        label: "Query Filters",
        colorName: "",
        wraps: [{
            name: "airline",
            showName: false,
            showFor: { org: ["ARC"] },
            fields: [{
                name: "airline",
                label: "Airline(s)",
                rLabel: "Airline code",
                help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                type: "typeahead",
                matchedOnly: true,
                value: [],
                labelKey: "description",
                codeKey: "acct",
                minChar: 0,
                url: window.ENV.references.airline,
                outputLabel: true,
                options: [],
                localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.airline) || '[]'),
                placeholder: "Enter Airline(s)"
            }]
        }, {
            name: "Period Date Range",
            showName: true,
            type: 'option',
            condition: 'OR',
            help: "Data is refreshed weekly",
            fields: [{
                name: "month_report_period",
                tab: "By Month",
                label: "By Month or Range",
                type: "monthYearPicker",
                selectsRange: true,
                startDate: [(new Date().getMonth() + 1 + "/" + new Date().getFullYear())],
                endDate: [(new Date().getMonth() + 1 + "/" + new Date().getFullYear())],
                show: true
            }, {
                name: "quarter_report_period",
                tab: "By Quarter",
                label: "By Quarter or Range",
                type: "quarterPicker",
                selectsRange: true,
                startDate: ["Q" + (Math.ceil((new Date().getMonth() + 1) / 3) + "/" + new Date().getFullYear())],
                endDate: ["Q" + (Math.ceil((new Date().getMonth() + 1) / 3) + "/" + new Date().getFullYear())],
                show: false
            }]
        }]
    }],
    chartsLists: [
        {
            label: "",
            wraps: [
                {
                    name: "summary",
                    label: "Performance Metrics",
                    showName: false,
                    fields: [{
                        name: "summary",
                        label: "transactions",
                        help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                        type: "SummaryCards",
                        rowCols: 4,
                        serviceKeys: {
                            label: "summary",
                            values: [
                                "transaction_count",
                                "transaction_count_all",
                                "sales",
                                "sales_all",
                                "avg_sales",
                                "avg_sales_all",
                                "commission",
                                "commission_all",
                                "est_cash_savings",
                                "est_cash_savings_all",
                                "share"
                            ]
                        },
                        keys: {
                            label: "summary",
                            values: [
                                "transaction_count",
                                "sales",
                                "avg_sales",
                                "commission",
                                "est_cash_savings",
                                "share"
                            ],
                            extensionAllowed: ["INTERNAL", "INTERNAL", "INTERNAL"],
                            multiValueExtensions: [{
                                key: "_all",
                                label: "All Carriers"
                            }, {
                                key: "",
                                label: "Carrier"
                            }],
                            
                            vLabels: ["Transactions", "Sales", "Average Sale", "Commission Paid", "Estimated Cash Savings", "Ticket Market Share"],
                            symbol: ["", "$", "$", "$", "$", ".%"],
                            percentSymbol: ".%"
                        }
                    }]
                }, {
                    name: "Trends",
                    label: "Trends",
                    showName: true,
                    compareTrendOptions: [thisYear, thisYear - 1, thisYear - 2],
                    type: "options",
                    defaultTab: "trends",
                    tabs: [
                    {
                        name: "trends",
                        label: "Ticket Trends",
                        showName: true,
                        fields: [{
                            name: "trends",
                            label: "Ticket Sales",
                            help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                            type: "XYChart",
                            enableLines: true,
                            enableDots: true,
                            enableArea: true,
                            enableValues: false,
                            labels: { x: "Timeframe", y: "Sales" },
                            serviceKeys: {
                                label: "purchase_month",
                                values: ["transaction_count", "transaction_count_share", "net_sales", "net_sales_share"]
                            },
                            keys: {
                                label: "purchase_month",
                                values: ["net_sales"],
                                legends: [],
                                legendToAppend: "Sales in ",
                                headers: {},
                                restrictValues: true,
                                symbol: "$"
                            },
                            spacing: 6
                        }, {
                            name: "trends",
                            label: "Ticket Transactions",
                            help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                            type: "XYChart",
                            enableLines: true,
                            enableDots: true,
                            enableArea: true,
                            enableValues: false,
                            labels: { x: "Timeframe", y: "Transactions" },
                            serviceKeys: {
                                label: "purchase_month",
                                values: ["transaction_count", "transaction_count_share", "net_sales", "net_sales_share"]
                            },
                            keys: {
                                label: "purchase_month",
                                values: ["transaction_count"],
                                legends: [],
                                legendToAppend: "Transactions in ",
                                headers: {
                                    blended_pay: "Total Actual Payment Amount"
                                },
                                restrictValues: true,
                                symbol: ""
                            },
                            spacing: 6
                        }]
                    }, {
                            name: "ndcTrends",
                            label: "NDC Trends",
                            showName: true,
                            fields: [{
                                name: "trends",
                                label: "NDC Sales",
                                help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                                type: "XYChart",
                                enableBars: true,
                                enableValues: false,
                                labels: { x: "Timeframe", y: "Sales" },
                                serviceKeys: {
                                    label: "purchase_month",
                                    values: ["transaction_count", "transaction_count_share", "net_sales", "net_sales_share"]
                                },
                                keys: {
                                    label: "purchase_month",
                                    values: ["net_sales_ndc"],
                                    legends: [],
                                    legendToAppend: "Sales in ",
                                    headers: {},
                                    restrictValues: true,
                                    symbol: "$"
                                },
                                spacing: 6
                            }, {
                                name: "trends",
                                label: "NDC Transactions",
                                help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                                type: "XYChart",
                                enableBars: true,
                                enableValues: false,
                                labels: { x: "Timeframe", y: "Transactions" },
                                serviceKeys: {
                                    label: "purchase_month",
                                    values: ["transaction_count", "transaction_count_share", "net_sales", "net_sales_share"]
                                },
                                keys: {
                                    label: "purchase_month",
                                    values: ["transaction_count_ndc"],
                                    legends: [],
                                    legendToAppend: "Transactions in ",
                                    headers: {
                                        blended_pay: "Total Actual Payment Amount"
                                    },
                                    restrictValues: true,
                                    symbol: ""
                                },
                                spacing: 6
                            }]
                        }, {
                            name: "edifactTrends",
                            label: "EDIFACT Trends",
                            showName: true,
                            fields: [{
                                name: "trends",
                                label: "EDIFACT Sales",
                                help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                                type: "BarChart",
                                orientation: "H",
                                enableValues: false,
                                labels: { y: "Timeframe", x: "Sales" },
                                serviceKeys: {
                                    label: "purchase_month",
                                    values: ["transaction_count", "transaction_count_share", "net_sales", "net_sales_share"]
                                },
                                keys: {
                                    label: "purchase_month",
                                    values: ["net_sales_edifact"],
                                    legends: [],
                                    legendToAppend: "Sales in ",
                                    headers: {},
                                    restrictValues: true,
                                    symbol: "$"
                                },
                                spacing: 6
                            }, {
                                name: "trends",
                                label: "EDIFACT Transactions",
                                help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                                type: "BarChart",
                                orientation: "H",
                                enableValues: false,
                                labels: { y: "Timeframe", x: "Transactions" },
                                serviceKeys: {
                                    label: "purchase_month",
                                    values: ["transaction_count", "transaction_count_share", "net_sales", "net_sales_share"]
                                },
                                keys: {
                                    label: "purchase_month",
                                    values: ["transaction_count_edifact"],
                                    legends: [],
                                    legendToAppend: "Transactions in ",
                                    headers: {
                                        blended_pay: "Total Actual Payment Amount"
                                    },
                                    restrictValues: true,
                                    symbol: ""
                                },
                                spacing: 6
                            }]
                        }
                    ],
                }, {
                    name: "creditcash",
                    label: "Credit",
                    showName: false,
                    fields: [{
                        name: "credit_cash_split",
                        label: "Credit / Cash Sales",
                        help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                        type: "DonutChart",
                        semicircle: true,
                        showTotal: true,
                        showPercentage: true,
                        totalText: "SALES",
                        theme: { colors: ['#189BB0', "#75E6DA", "#D4F1F4"] },
                        serviceKeys: {
                            label: "payment_type",
                            values: ["sales", "sales_share"]
                        },
                        keys: {
                            label: "payment_type",
                            value: "sales",
                            symbol: "$",
                            symbols: ["", "$", ".%", "$", ".%", "$", ".%"],
                            percentSymbol: ".%",
                            sort: ""
                        },
                        width: 350,
                        spacing: 6
                    }, {
                        name: "cc_breakdown",
                        label: "Credit Card Sales",
                        help: "",
                        type: "DonutChart",
                        totalText: "SALES",
                        showTotal: true,
                        showPercentage: true,
                        labels: { x: "Sales", y: "Credit Card" },
                        serviceKeys: {
                            label: "credit_card_provider",
                            values: ["cc_sales"]
                        },
                        keys: {
                            label: "credit_card_provider",
                            value: "cc_sales",
                            symbol: "$",
                            percentSymbol: ".%",
                            sort: ""
                        },
                        width: 320,
                        spacing: 6
                    }]
                }, {
                    name: "transactions",
                    label: "Transactions",
                    showName: false,
                    fields: [{
                        name: "transaction_type",
                        label: "Transaction Types",
                        help: "",
                        type: "TableChart",
                        fieldTabs: true,
                        serviceKeys: {
                            label: "transaction_type",
                            values: [
                                "count",
                                "domestic_count",
                                "international_count",
                                "share",
                                "domestic_share",
                                "international_share"
                            ]
                        },
                        keys: {
                            label: "transaction_type",
                            values: [
                                {tabName: "TOTAL", values: ["count", "share"]}, 
                                {tabName: "DOMESTIC", values: ["domestic_count", "domestic_share"]},
                                {tabName: "INTERNATIONAL", values: ["international_count", "international_share"]}
                            ],
                            headers: {
                                transaction_type: "TYPE",
                                count: "TRANSACTIONS", 
                                share: "MARKET SHARE",
                                domestic_count: "TRANSACTIONS",
                                domestic_share: "MARKET SHARE",
                                international_count: "TRANSACTIONS",
                                international_share: "MARKET SHARE"
                            },
                            restrictValues: true,
                            barsAt: 2,
                            symbols: ["", "", ".%"],
                            noBorders: true,
                            hideFilter: true,
                            sort: "totalSales"
                        },
                        spacing: 6
                    }, {
                        name: "gds_distribution",
                        label: "GDS Distribution",
                        help: "",
                        enableValues: true,
                        theme: { colors: ['#189BB0', "#78dded"] },
                        type: "TreemapChart",
                        serviceKeys: {
                            label: "gds_source",
                            values: ["transaction_count", "transaction_count_share"],
                        },
                        keys: {
                            label: "gds_source",
                            legends: "GDS",
                            value: "transaction_count",
                            sort: "transaction_count",
                            past: "transaction_count_prior",
                            symbol: "",
                            symbols: ["", "", ".%", "", ".%", "", ".%"],
                            percentSymbol: ".%"
                        },
                        height: 350,
                        spacing: 6
                    }]
                }, {
                    name: "TopAgency",
                    label: "Top Agency",
                    showName: false,
                    fields: [{
                        name: "top_agencies",
                        label: "Top Travel Agencies",
                        help: "",
                        type: "TableChart",
                        serviceKeys: {
                            label: "agency_number",
                            values: ["sales", "transaction_count"]
                        },
                        keys: {
                            label: "agency_number",
                            values: ["transaction_count", "transaction_count_percent_diff", "sales", "sales_percent_diff"],
                            headers: {
                                agency_number: "TRAVEL AGENCY",
                                sales: "SALES", 
                                sales_percent_diff: "CHANGE YOY",
                                transaction_count: "TRANSACTIONS",
                                transaction_count_percent_diff: "CHANGE YOY",
                            },
                            symbols: ["","",".%","$",".%"],
                            restrictValues: true,
                            columnSeparatorAt: [1, 3],
                            showPercentageStatusIcons: true,
                            hideFilter: true,
                            sort: "sales"
                        },
                        spacing: 12
                    }]
                }
            ]
        }
    ]
};

