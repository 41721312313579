import React, { useState, useEffect } from 'react';
import { Toast } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import './Toasts.scss';

const StatusToast = () => {
    const ref: any = {
        "SUCCESS": "fds-glyphs-checkmark3",
        "DANGER": "fds-glyphs-warning2",
        "WARNING": "fds-glyphs-warning2",
        "INFO": "fds-glyphs-notification2"
    }

    const statusRef = useSelector((store: any) => store.statusRef);
    const dispatch = useDispatch();

    const [data, setData] = useState(statusRef);

    useEffect(() => {
        setData(statusRef);
        if (statusRef.type?.toLowerCase() === "success") {
            setTimeout(() => {
                dispatch({ type: "STATUS", data: { title: "", message: "" } })
            }, 2500)
        }
    }, [statusRef, dispatch])

    return (
        <React.Fragment>
            {data.message !== null && data.message !== undefined && (data.message + "").length > 0 ?
                <React.Fragment>
                    <Toast
                        className={data.type?.replace("-WAIT", "")?.toLowerCase()}
                        show={data.message !== null && data.message !== undefined && (data.message + "").length > 0}
                        onClose={() => {
                            dispatch({ type: "STATUS", data: { title: "", message: "" } })
                            localStorage.setItem("isTimeoutShowed", "false");
                            localStorage.setItem("lastActive", new Date().toString());
                        }}
                    >
                        <Toast.Header>
                            <strong className="me-auto"><i className={ref[data.type?.replace("-WAIT", "")]}></i> {data.title}</strong>
                        </Toast.Header>
                        <Toast.Body>{data.message}</Toast.Body>
                    </Toast>
                    <div className="loader"></div>
                </React.Fragment>
                : null
            }
        </React.Fragment>
    )
}

export default StatusToast;