import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { Container, Row, Col, Dropdown, Alert, ListGroup, Table } from 'react-bootstrap';
import * as FDS from '@arctravel/react-fds/lib';
import { useSelector, useDispatch } from 'react-redux';
import { templates, refreshActivity, Const } from '../utils';
import SaveTemplate from '../modals/SaveTemplate';
import { removeSavedTemplatesAction, getAllSavedTemplatesAction, updateSavedTemplatesAction } from '../../store/actions';
import { getOts } from '../../okta/ls';

import './Hub.scss';
import Feedback from '../modals/Feedback';
import SearchSortHeader from '../utils/SearchSortHeader';

declare const window: any;

export const HubTemplates = () => {
    const statusLogo: any = {
        "2": "fds-glyphs-profile",
        "0": "fds-glyphs-user3",
        "Active": "fds-glyphs-play",
        "Pause": "fds-glyphs-pause",
        "Paused": "fds-glyphs-pause"
    }

    const statusColor: any = {
        "2": "success",
        "0": "info",
        "A2": "warning",
        "Active": "success",
        "Pause": "danger",
        "Paused": "danger"
    }

    const buttons = ["saved", "sched", "auto"]
    const tempplateStatus = ["Active", "Pause"]

    const historyNav = useHistory();
    const savedAllTemplatesRef = useSelector((state: any) => state.savedAllTemplatesRef);
    const customersRef: any = useSelector((state: any) => state.customersRef);
    const [savedAllTemplates, setSavedAllTemplates]: any = useState([...savedAllTemplatesRef]);
    const [savedTemplates, setSavedTemplates]: any = useState([...savedAllTemplatesRef]);
    const selectedTemplate = { template_name: "" };
    const [showTemplateModal, setShowTemplateModal] = useState(false);
    const [cust, setCust] = useState(customersRef.customer);

    const [tIp, setTIp]: any = useState({});
    const [showIndex, setShowIndex] = useState([-1]);

    const dispatch = useDispatch();

    const [arrowButton, setArrowButton] = useState(buttons[0])

    const removeTemplate = (tObj: any) => {
        dispatch(removeSavedTemplatesAction({
            id: tObj.Id,
            report_type: tObj.report_type,
            template_name: tObj.template_name
        }))
    }

    const saveTemplate = (tObj: any, hObj: any) => {
        refreshActivity();
        setTIp({ ...tObj });
        setShowTemplateModal(true);
    }

    const reRun = (tObj: any, hObj: any) => {
        dispatch({ type: "RERUN_TEMPLATE", data: { ...hObj, template_name: tObj.template_name } });
        historyNav.push('/reports/' + tObj.report_type);
    }

    //Sorting : default sort by userId in desc order
    const [sortKey, setSortKey] = useState<string>("email")
    const [sortOrder, setSortOrder] = useState("ascn")
    const [filterIp, setFilterIp]: any = useState({});
    const [headers, setHeaders]: any = useState([])

    const changeSort = (key: string) => {
        setSortOrder(key === sortKey ? (sortOrder === "ascn" ? "desc" : "ascn") : "ascn")
        setSortKey(key)
    }

    // Logic to filter and sort data based to type and selection
    const sortedData = useCallback(
        () => {
            if (!sortKey && !sortOrder) return savedTemplates

            const sortedData = savedTemplates.filter((obj: any) => {
                let count = 0;
                for (let hi = 0; hi < Object.keys(filterIp).length; hi++) {
                    const key = Object.keys(filterIp)[hi];
                    if (filterIp[key].length > 0 && key === "status" && obj['request']['schedule']['status'].toLowerCase().includes(filterIp[key].toLowerCase())) {
                        // TO CONTINUE
                        count++;
                    }
                    if (filterIp[key].length > 0 && key !== "status" && (obj[key].toLowerCase().includes(filterIp[key].toLowerCase()))) {
                        count++;
                    }
                }
                return count === Object.keys(filterIp).filter(k => filterIp[k].length > 0).length;
            }).sort((a: any, b: any) => {
                if (sortKey === "status") {
                    return a['request']['schedule']['status'].toLowerCase() > b['request']['schedule']['status'].toLowerCase() ? 1 : -1
                } else {
                    return a[sortKey].toLowerCase() > b[sortKey].toLowerCase() ? 1 : -1;
                }
            });

            if (sortOrder === "desc") {
                return sortedData.reverse();
            }

            return sortedData

        }, [savedTemplates, sortKey, sortOrder, filterIp]
    );

    // Available to Schedule Template only
    const updateStatus = (row: any, Id: string, favorite = {}) => {
        if (Object.keys(favorite).length > 0) {
            dispatch(updateSavedTemplatesAction([{ ...favorite, "Id": Id }], row.template_name))
        } else {
            dispatch(updateSavedTemplatesAction(
                [{
                    "Id": Id,
                    "request": {
                        "schedule": {
                            "status": row.schedule.status === tempplateStatus[0] ? tempplateStatus[1] : tempplateStatus[0]
                        }
                    }
                }], row.template_name))
        }
    }

    useEffect(() => {
        refreshActivity();
        setSavedAllTemplates(savedAllTemplatesRef);
        const headers: any = [
            { "key": "template_name", "label": "Template Name", filter: { type: "text" } },
            { "key": "report_type", "label": "Type", filter: { type: "select", hub: true, options: [] } },
            { "key": "email", "label": "Created By/On", filter: { type: "text" } },
            { "key": `${arrowButton === "sched" ? "status" : "shared"}`, "label": `${arrowButton === "sched" ? "Status" : "Shared"}`, filter: { type: "select", hub: true, options: [] } },
            { "key": "action", "label": "Action", filter: { type: "clear" } }
        ]
        const savedOrScheTemplates = savedAllTemplatesRef.filter((temp: any) => {
            return arrowButton === "auto" ? temp.auto_generated : (arrowButton === "saved" && !temp.auto_generated ? temp.scheduled === "false" : temp.scheduled === "true")
        })
        setSavedTemplates(savedOrScheTemplates.sort((a: any, b: any) => a.shared > b.shared ? 1 : -1))

        // Dynamic dropdown options for serch dropdown options        
        savedOrScheTemplates.forEach((temp: any) => {
            headers.forEach((row: any, i: any) => {
                if (row.key === "status" && row.filter?.options?.indexOf(temp['request']['schedule']['status']) < 0) {
                    headers[i].filter?.options?.push(temp['request']['schedule']['status']);
                } else if (row.key !== "status" && row.filter?.type === "select" && row.filter?.options?.indexOf(temp[row.key]) < 0) {
                    headers[i].filter?.options?.push(temp[row.key]);
                }
            })
        })
        setHeaders(headers)
        setSortOrder("ascn")
        setSortKey("email")

        return () => {
            setFilterIp({})
            setSortOrder("ascn")
            setSortKey("email")
            setHeaders([])
            setShowIndex([-1])
            setTIp({})
        }
    }, [savedAllTemplatesRef, arrowButton]);

    useEffect(() => {
        setCust(customersRef.customer);
    }, [customersRef.customer]);

    useEffect(() => {
        dispatch({ type: "REPORT_IP", data: {} });
        setFilterIp({})
        window.scrollTo(0, 0);
        dispatch(getAllSavedTemplatesAction());
        setShowIndex([-1])
    }, [dispatch, customersRef.viewAs.org]);

    return (
        <React.Fragment>
            <Feedback page="Saved Templates" />
            <FDS.FDSLevel type="two" name="hubPage" mainTitle="Saved Templates" subTitle="Access and manage all your Saved Report Templates">
                <Container className="fds-pl-container first float">
                    <Row className="fds-pl-column p-3">
                        <Col className="pe-0" sm={2}>
                            <ListGroup className="arrowList">
                                <ListGroup.Item active={arrowButton === buttons[0]} onClick={() => setArrowButton(buttons[0])}>Saved Template</ListGroup.Item>
                                <ListGroup.Item active={arrowButton === buttons[1]} onClick={() => setArrowButton(buttons[1])}>Scheduled Template</ListGroup.Item>
                                {/*<ListGroup.Item active={arrowButton === buttons[2]} onClick={() => setArrowButton(buttons[2])}>Event Template</ListGroup.Item>*/}
                            </ListGroup>
                        </Col>

                        <Col className="arrowContent ps-0" sm={10}>
                            <div className="card content">
                                <div className="fds-table-wrap">
                                    <Table className="templateTable fds-table table-striped">
                                        <SearchSortHeader headers={headers} sortKey={sortKey} sortOrder={sortOrder} onSort={changeSort} search={true} onChange={setFilterIp} filterIp={filterIp} />
                                        <tbody>
                                            {
                                                sortedData().map((tObj: any, i: number) => {
                                                    const owner = tObj.user_id?.toLowerCase();
                                                    const hObj = tObj?.request;
                                                    const Id = tObj.Id
                                                    return (
                                                        <tr className={`${showIndex.indexOf(i) >= 0 ? "selected" : ""}`} key={i}>
                                                            <td>
                                                                <div className="repTitle" onClick={() => {
                                                                    setShowIndex((prev: number[]) => {
                                                                        const ind = prev.indexOf(i);
                                                                        if (ind >= 0) {
                                                                            return [...prev.filter(item => item !== i)];
                                                                        } else {
                                                                            return [...prev, i]
                                                                        }
                                                                    })
                                                                }}>
                                                                    <i className={`${showIndex.indexOf(i) >= 0 ? "fds-glyphs-arrow-down5" : "fds-glyphs-caret-right"} me-3`} />
                                                                    {tObj.favorite === "true" && tObj.user_id === getOts()?.idToken?.sub ? <i className='fds-glyphs-star-full' /> : ""} {tObj.template_name} <br />
                                                                    {tObj.template_description ? <small style={{ paddingLeft: '2rem', display: 'inline-block', fontWeight: '600' }}>{tObj.template_description}</small> : null}
                                                                    {
                                                                        tObj.viewAs?.org ?
                                                                            <div>
                                                                                <small className='ps-3 ms-3 pt-3 pb-0'>
                                                                                    View As: <b>{tObj.viewAs.orgName}</b>
                                                                                </small>
                                                                            </div> :
                                                                            null
                                                                    }
                                                                </div>
                                                                {showIndex.indexOf(i) >= 0 ? <div className="pt-3 ps-3 ms-3">
                                                                    {tObj.auto_generated ?
                                                                        <div>
                                                                            <div>Airline: {tObj.request?.airline?.toString()}</div>
                                                                            <div>Email To: {tObj.request?.schedule?.email_config?.emailTo?.map((mail: string, mi: number) => <><a key={mi} href={`mailto:${mail}`}>{mail}</a>; </>)}</div>
                                                                            <div>CC: {tObj.request?.schedule?.email_config?.CC?.length > 0 ? tObj.request?.schedule?.email_config?.CC?.map((mail: string, mi: number) => <><a key={mi} href={`mailto:${mail}`}>{mail}</a>; </>) : FDS.valueOrDash(null)}</div>
                                                                            <div>BCC: {tObj.request?.schedule?.email_config?.BCC?.length > 0 ? tObj.request?.schedule?.email_config?.BCC?.map((mail: string, mi: number) => <><a key={mi} href={`mailto:${mail}`}>{mail}</a>; </>) : FDS.valueOrDash(null)}</div>
                                                                        </div> :
                                                                        null
                                                                    }
                                                                    {{ ...templates[tObj.report_type] }?.lists?.map((list: any, q: number) => {
                                                                        return list.wraps.map((wrap: any, i: number) => {
                                                                            return (
                                                                                <React.Fragment key={i}>
                                                                                    {wrap.fields.map((field: any, j: number) => {
                                                                                        return (
                                                                                            <React.Fragment key={j}>
                                                                                                {
                                                                                                    hObj[field.name]?.length > 0 || (typeof hObj[field.name] === "object" && !Array.isArray(hObj[field.name])) ? (
                                                                                                        <span className="fds-tag info ms-0 mb-1">
                                                                                                            {field.hLabel || field.label}:
                                                                                                            <b className='ps-2'>
                                                                                                                {
                                                                                                                    typeof hObj[field.name] === "object" && !Array.isArray(hObj[field.name]) ?
                                                                                                                        Object.keys(hObj[field.name])
                                                                                                                            .sort((a: any, b: any) => a < b ? 1 : -1)
                                                                                                                            .map((item: any, k: number) => {
                                                                                                                                return (<span key={k}>
                                                                                                                                    {hObj[field.name][item] ?
                                                                                                                                        <React.Fragment>
                                                                                                                                            {!field.startDate ? <span style={{ textTransform: 'capitalize' }}>{item}</span> : null}
                                                                                                                                            {field.startDate && item === "startDate" ?
                                                                                                                                                hObj[field.name]["startDate"][0] + " " + (hObj[field.name]["endDate"][0] ? FDS.valueOrDash(null) + " " + hObj[field.name]["endDate"][0] : "") +
                                                                                                                                                (hObj[field.name]["startDate"].length > 1 ? " vs " + hObj[field.name]["startDate"][1] + " " + (hObj[field.name]["endDate"][1] ? FDS.valueOrDash(null) + " " + hObj[field.name]["endDate"][1] : "") : "") :
                                                                                                                                                null}
                                                                                                                                            {!field.startDate ? `[${hObj[field.name][item]?.toString()}]   &nbsp;` : null}
                                                                                                                                        </React.Fragment>
                                                                                                                                        : null
                                                                                                                                    }
                                                                                                                                </span>)
                                                                                                                            }) :
                                                                                                                        Array.isArray(hObj[field.name]) ?
                                                                                                                            hObj[field.name].map((item: string, i: number) => {
                                                                                                                                return field.options.filter((opt: any) => opt[field.codeKey] === item)[0]?.[field.labelKey] || item
                                                                                                                            }).join(", ").toUpperCase() :
                                                                                                                            (field.type === "select" ?
                                                                                                                                field.options.filter((opt: any) => opt['key']?.toLowerCase() === hObj[field.name].toLowerCase())[0]?.['value'] :
                                                                                                                                hObj[field.name]?.toString().replace(/,/gi, ', ')).toUpperCase()
                                                                                                                }
                                                                                                            </b>
                                                                                                        </span>
                                                                                                    ) : null
                                                                                                }
                                                                                                {
                                                                                                    field.type === "toggleCheckbox" ?
                                                                                                        field.options.map((item: any, k: number) => {
                                                                                                            return (
                                                                                                                <span className="fds-tag info ms-0 mb-1">
                                                                                                                    {item.value}:
                                                                                                                    <b>{hObj[item.key] ?
                                                                                                                        (<i className="fds-glyphs-checkbox-checked2 infoTxt" />) :
                                                                                                                        (<i className="fds-glyphs-cancel-square2 dangerTxt" />)
                                                                                                                    }</b>
                                                                                                                </span>
                                                                                                            )
                                                                                                        }) : null
                                                                                                }
                                                                                            </React.Fragment>
                                                                                        )
                                                                                    })}
                                                                                </React.Fragment>
                                                                            )
                                                                        })
                                                                    })}
                                                                </div> : null}
                                                            </td>
                                                            <td style={{ whiteSpace: 'nowrap' }}>
                                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                                    <small className="initial rType" style={{ background: window.ENV.props.reportTitles[tObj.report_type]?.color }}>{(window.ENV.props.reportTitles[tObj.report_type]?.mainTitle || tObj.report_type).match(/\b(\w)/g).join('')}</small>
                                                                    <small>
                                                                        {
                                                                            (window.ENV.props.reportTitles[tObj.report_type]?.shortTitle || window.ENV.props.reportTitles[tObj.report_type]?.mainTitle || tObj.report_type)?.length > 12 ?
                                                                                (window.ENV.props.reportTitles[tObj.report_type]?.shortTitle || window.ENV.props.reportTitles[tObj.report_type]?.mainTitle || tObj.report_type)?.split(" ").map((w: any) => <div key={w}>{w}</div>) :
                                                                                (window.ENV.props.reportTitles[tObj.report_type]?.shortTitle || window.ENV.props.reportTitles[tObj.report_type]?.mainTitle || tObj.report_type)
                                                                        }
                                                                        <div className='childTitle'>{window.ENV.props.reportTitles[tObj.report_type]?.childTitle || null}</div>
                                                                    </small>
                                                                </div>
                                                            </td>
                                                            <td style={{ whiteSpace: 'nowrap' }}>
                                                                {tObj.email || tObj.user_id} <br />
                                                                {tObj.create_dt}
                                                            </td>
                                                            {arrowButton === buttons[1] ?
                                                                <td className={`${statusColor[hObj?.schedule?.status]}Txt`} style={{ whiteSpace: 'nowrap' }}>
                                                                    <i className={`${statusLogo[hObj?.schedule?.status]}`} /> {hObj?.schedule?.status}
                                                                </td>
                                                                :
                                                                <td className={`${statusColor[tObj.user_org === Const.ARC_ROLE && tObj.shared !== "0" ? "A2" : tObj.shared]}Txt`} style={{ whiteSpace: 'nowrap' }}>
                                                                    <i className={`${statusLogo[tObj.shared]}`} /> {tObj.shared === "2" ? "Shared with " + (tObj.user_org !== Const.ARC_ROLE ? `${cust.name || tObj.user_org}` : tObj.user_org) : "Private"}
                                                                </td>
                                                            }
                                                            <td className="editMenu">
                                                                <Dropdown>
                                                                    <Dropdown.Toggle variant="link"><i className="fds-glyphs-more2"></i></Dropdown.Toggle>

                                                                    <Dropdown.Menu>
                                                                        {arrowButton === buttons[1] &&
                                                                            <Dropdown.Item
                                                                                href="#"
                                                                                onClick={() => updateStatus(hObj, Id)}>
                                                                                <i className={hObj?.schedule?.status === tempplateStatus[0] ? statusLogo[tempplateStatus[1]] : statusLogo[tempplateStatus[0]]} />
                                                                                {hObj?.schedule?.status === tempplateStatus[0] ? tempplateStatus[1] : "Play"}
                                                                            </Dropdown.Item>
                                                                        }

                                                                        {(cust?.data_source?.indexOf("GLOBAL") < 0 && tObj.report_type === "JOURNEY_ESTIMATES") || tObj.report_type === "ARCCIDS" ?
                                                                            null :
                                                                            <>
                                                                                <Dropdown.Item href="#" onClick={() => saveTemplate(tObj, hObj)}><i className="fds-glyphs-file-plus" />Clone</Dropdown.Item>
                                                                                <Dropdown.Item href="#" onClick={() => reRun(tObj, hObj)}><i className="fds-glyphs-rotate-cw3" />Load / Edit</Dropdown.Item>
                                                                            </>
                                                                        }
                                                                        {owner === (getOts()?.idToken?.sub || getOts()?.idToken?.claims?.preferred_username)?.toLowerCase() ? <Dropdown.Item href="#" onClick={() => removeTemplate(tObj)}><i className="fds-glyphs-bin2" />Remove</Dropdown.Item> : null}
                                                                        {tObj.favorite === "true" && tObj.user_id === getOts()?.idToken?.sub && <Dropdown.Item href="#" onClick={() => updateStatus(hObj, Id, { "favorite": "false" })}><i className="fds-glyphs-cancel-square2" />Remove Favorite</Dropdown.Item>}
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                        {sortedData().length <= 0 ? <tfoot>
                                            <tr>
                                                <td colSpan={100}>
                                                    <Alert data-testid="table-main-no-data" className="m-3 w-100" variant="primary">
                                                        <div className="icon"><i className="fds-glyphs-info2"></i></div>
                                                        <div className="body">There are currently no templates available.</div>
                                                    </Alert>
                                                </td>
                                            </tr>
                                        </tfoot> : null}
                                    </Table>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <SaveTemplate ip={tIp.request} type={tIp.report_type} savedTemplates={savedAllTemplates} selectedTemplate={selectedTemplate} saveAs={false} show={showTemplateModal} onHide={setShowTemplateModal} />
                <div></div>
            </FDS.FDSLevel>
        </React.Fragment>
    )
}