import React from 'react';
import { Button } from 'react-bootstrap';
import SortButton from './SortableButton/SortButton';
import * as FDS from '@arctravel/react-fds/lib';
import { capitalize } from '../utils';

declare const window: any;

interface TabelProps {
    headers: any,
    sortKey: string,
    sortOrder: any,
    onSort: any,
    search: boolean,
    onChange: any,
    filterIp: any,
}

const SearchSortHeader = (props: TabelProps) => {

    const { headers, sortKey, search, onSort, sortOrder, onChange, filterIp } = props
    const alt: any = { "0": "Private", "2": "Shared", "ZERO": "No Records" }

    return (
        <thead>
            <tr>
                {headers?.map((row: any) => {
                    return (
                        <SortButton
                            key={row.key}
                            sortOrder={sortOrder}
                            columnKey={row.key}
                            sortKey={sortKey}
                            columnHeader={row.label}
                            help={row.help || ""}
                            position={row.filter.position || (row.filter?.type === "number" ? "right" : "left")}
                            onClick={() => row.key !== "action" && onSort(row.key)}
                        />
                    )
                })}
            </tr>

            {search && <tr>
                {headers?.map((row: any, ri: number) => {
                    let ele: any = null;
                    switch (row.filter?.type) {
                        case "date":
                            ele = (
                                <FDS.FDSDatePicker
                                    startDate={[filterIp[row.key]?.startDate || FDS.toMMDDYYYY(new Date(new Date().setDate(new Date().getDate() - (row.filter?.daysBefore || 0))))]}
                                    endDate={[filterIp[row.key]?.endDate || FDS.toMMDDYYYY(new Date(new Date().setDate(new Date().getDate() + (row.filter?.daysAfter || 0))))]}
                                    minDate={FDS.toMMDDYYYY(new Date(new Date().setDate(new Date().getDate() - (row.filter?.daysBefore >= 0 ? row.filter?.daysBefore : 20000))))}
                                    maxDate={FDS.toMMDDYYYY(new Date(new Date().setDate(new Date().getDate() + (row.filter?.daysAfter >= 0 ? row.filter?.daysAfter : 20000))))}
                                    selectsRange={true}
                                    onChange={(data) => {
                                        console.log("RET:1:", data);
                                        onChange((prev: any) => {
                                            prev[row.key] = data;
                                            console.log("RET:2:", prev);
                                            return { ...prev }
                                        });
                                    }} />
                            )
                            break;
                        case "select":
                            ele = (
                                <select className="form-select" aria-label={`select ${row.key}`} value={filterIp[row.key] || ""} onChange={(ev) => onChange((prev: any) => {
                                    prev[row.key] = ev.target.value;
                                    if (prev[row.key].length <= 0) {
                                        delete prev[row.key];
                                    }
                                    return { ...prev };
                                })}>
                                    <option value="">-- Select --</option>
                                    {row?.filter?.hub && row?.filter?.options?.sort((a: string, b: string) => {
                                        const titleA = window.ENV.props.reportTitles[a]?.shortTitle || window.ENV.props.reportTitles[a]?.mainTitle;
                                        const titleB = window.ENV.props.reportTitles[b]?.shortTitle || window.ENV.props.reportTitles[b]?.mainTitle;
                                        return titleA <= titleB ? -1 : 1
                                    }).map((op: any, i: number) => {
                                        const title = window.ENV.props.reportTitles[op]?.shortTitle || window.ENV.props.reportTitles[op]?.mainTitle;
                                        const child = window.ENV.props.reportTitles[op]?.childTitle;
                                        return <option className='hub' key={i} value={op}>{(title ? title + (child ? " - " + child : "") : title) || op?.replace(/\b(?:ZERO|0|2)\b/gi, (matched: any) => { return alt[matched] })}</option>
                                    })}
                                    {(!row?.filter?.hub) && row?.filter?.options?.length > 0 && row?.filter?.options?.map((op: any, i: number) => <option className='dyn' key={i} value={op.key}>{op.value}</option>)}
                                </select>
                            )
                            break;
                        case "clear":
                            ele = (<Button variant='secondary' onClick={() => { onChange({}) }}>Clear</Button>);
                            break;
                        case "text":
                            ele = (<input type="text" value={filterIp[row.key] || ""} placeholder={capitalize(row.label)}
                                onChange={(ev) => onChange((prev: any) => {
                                    prev[row.key] = ev.target.value;
                                    if (prev[row.key].length <= 0) {
                                        delete prev[row.key];
                                    }
                                    return { ...prev };
                                })} />)
                            break;
                        case "number":
                            ele = (<input type="text" value={filterIp[row.key] || ""} placeholder={capitalize(row.label)}
                                onChange={(ev) => onChange((prev: any) => {
                                    prev[row.key] = ev.target.value;
                                    if (prev[row.key].length <= 0) {
                                        delete prev[row.key];
                                    }
                                    return { ...prev };
                                })} />)
                            break;
                        default:
                            break;
                    }

                    return (
                        <th key={ri}>{ele}</th>
                    );
                })}
            </tr>}
        </thead>
    )
}

export default SearchSortHeader